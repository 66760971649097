import React, { useState, useEffect } from 'react';
import ProductItem from './ProductItem';
import './ProductList.css';

const ProductList = ({ products, isLoading, goToProductDetails }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 480 && window.innerWidth <= 768);

  // Add event listener to handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      setIsTablet(window.innerWidth > 480 && window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fullProducts = products.filter((product) => product.category !== 'chain');
  const jewelryProducts = products.filter((product) => product.category === 'chain');
  const orderedProducts = [...fullProducts, ...jewelryProducts];

  // For desktop, split into 3 columns
  const column1 = orderedProducts.filter((_, index) => index % 3 === 0);
  const column2 = orderedProducts.filter((_, index) => index % 3 === 1);
  const column3 = orderedProducts.filter((_, index) => index % 3 === 2);

  return (
    <section className="product-list-section">
      {isLoading ? (
        <div className="products-container">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="product-item skeleton-container">
              <div className="skeleton-image"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="products-container">
          {isMobile || isTablet ? (
            // Single column layout for mobile and tablet
            orderedProducts.map((product, index) => (
              <div
                key={product.id}
                className={`product-item ${index % 2 === 0 ? 'offset-left' : 'offset-right'}`}
              >
                <ProductItem
                  product={product}
                  goToProductDetails={goToProductDetails}
                />
              </div>
            ))
          ) : (
            // Three-column layout for desktop
            <>
              <div className="column1">
                {column1.map((product) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    goToProductDetails={goToProductDetails}
                  />
                ))}
              </div>
              <div className="column2">
                {column2.map((product) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    goToProductDetails={goToProductDetails}
                  />
                ))}
              </div>
              <div className="column3">
                {column3.map((product) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    goToProductDetails={goToProductDetails}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default ProductList;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, Timestamp, getDocs } from 'firebase/firestore'; // Importing getDocs for fetching cart items
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import './CheckoutShipping.css';

// US states, Venezuela states, and Europe regions
const usStates = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", 
  "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", 
  "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", 
  "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", 
  "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", 
  "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", 
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", 
  "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

const venezuelaStates = [
  "Amazonas", "Anzoátegui", "Apure", "Aragua", "Barinas", "Bolívar", "Carabobo", 
  "Cojedes", "Delta Amacuro", "Falcón", "Guárico", "Lara", "Mérida", "Miranda", 
  "Monagas", "Nueva Esparta", "Portuguesa", "Sucre", "Táchira", "Trujillo", 
  "Vargas", "Yaracuy", "Zulia"
];

const europeRegions = [
  "Andalusia", "Bavaria", "Catalonia", "Flanders", "Lombardy", "Normandy", "Provence",
  "Scotland", "Tuscany", "Wales"
];

const countries = ["United States", "Europe", "Venezuela"];

const CheckoutShipping = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [cartItems, setCartItems] = useState([]); // To store cart items

  const [shippingInfo, setShippingInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (user) {
      const fetchCartItems = async () => {
        const cartCollectionRef = collection(firestore, `users/${user.uid}/cart`);
        const cartSnapshot = await getDocs(cartCollectionRef);
        const cartData = cartSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCartItems(cartData); // Set fetched cart items
      };

      fetchCartItems();
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Format phone number as xxx-xxx-xxxx
    if (name === 'phoneNumber') {
      let formattedPhoneNumber = value.replace(/\D/g, ''); // Remove all non-numeric characters
      if (formattedPhoneNumber.length > 3 && formattedPhoneNumber.length <= 6) {
        formattedPhoneNumber = `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3)}`;
      } else if (formattedPhoneNumber.length > 6) {
        formattedPhoneNumber = `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
      }
      setShippingInfo({ ...shippingInfo, phoneNumber: formattedPhoneNumber });
    } else {
      setShippingInfo({ ...shippingInfo, [name]: value });
    }
  };

  const getStateOptions = () => {
    switch (shippingInfo.country) {
      case 'United States':
        return usStates;
      case 'Venezuela':
        return venezuelaStates;
      case 'Europe':
        return europeRegions;
      default:
        return [];
    }
  };

  const generateOrderNumber = () => {
    return `ORD-${Math.floor(Math.random() * 90000) + 10000}`;
  };

  const handleNextStep = async () => {
    try {
      if (user && cartItems.length > 0) {
        const userOrdersCollection = collection(firestore, `users/${user.uid}/orders`);
        const orderNumber = generateOrderNumber();
        const phoneNumberUnformatted = shippingInfo.phoneNumber.replace(/\D/g, ''); // Remove formatting
        
        // Creating the order with all shipping information and cart items
        await addDoc(userOrdersCollection, {
          firstName: shippingInfo.firstName,
          lastName: shippingInfo.lastName,
          address: shippingInfo.address,
          address2: shippingInfo.address2,
          city: shippingInfo.city,
          state: shippingInfo.state,
          zipCode: shippingInfo.zipCode,
          country: shippingInfo.country,
          phoneNumber: phoneNumberUnformatted,
          email: user.email,
          orderNumber,
          timestamp: Timestamp.now(),
          status: "Shipping info collected",
          items: cartItems, // Include cart items in the order
        });

        navigate('/checkout/payment', { state: { orderNumber, shippingInfo } });
      } else {
        alert("Your cart is empty. Please add items to your cart.");
      }
    } catch (error) {
      console.error("Error saving shipping information: ", error);
    }
  };

  return (
    <div className="checkout-shipping-container">
      <div className="checkout-shipping-content">
        <div className="checkout-shipping-left">
          <h2 className="checkout-shipping-title">Shipping Address</h2>
          <div className="checkout-shipping-address-fields">
            <input
              type="text"
              name="firstName"
              placeholder="First name"
              value={shippingInfo.firstName}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={shippingInfo.lastName}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={shippingInfo.address}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            />
            <input
              type="text"
              name="address2"
              placeholder="Address 2"
              value={shippingInfo.address2}
              onChange={handleChange}
              className="checkout-shipping-input"
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={shippingInfo.city}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            />
            <select
              name="country"
              value={shippingInfo.country}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
            {shippingInfo.country && (
              <select
                name="state"
                value={shippingInfo.state}
                onChange={handleChange}
                required
                className="checkout-shipping-input"
              >
                <option value="">Select a state</option>
                {getStateOptions().map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            )}
            <input
              type="text"
              name="zipCode"
              placeholder="Zip code"
              value={shippingInfo.zipCode}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone number"
              value={shippingInfo.phoneNumber}
              onChange={handleChange}
              required
              className="checkout-shipping-input"
              maxLength={12} // To ensure the format is xxx-xxx-xxxx
            />
          </div>

          <button className="checkout-shipping-confirm-button" onClick={handleNextStep}>
            Confirm & Continue
          </button>
        </div>
      </div>
    </div>
  );  
};

export default CheckoutShipping;

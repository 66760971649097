import React, { createContext, useState, useEffect, useContext } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, onSnapshot, addDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { useUser } from '../UserContext'; // Custom user hook

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { user } = useUser(); // Use the user hook to get the authenticated user
  const userId = user?.uid || null;

  useEffect(() => {
    if (userId) {
      const cartCollectionRef = collection(firestore, `users/${userId}/cart`);
      const unsubscribe = onSnapshot(cartCollectionRef, (snapshot) => {
        const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCartItems(items);
      });

      return () => unsubscribe(); // Cleanup the listener
    }
  }, [userId]);

  // Add or update an item in the cart
  const addToCart = async (newItem) => {
    const cartCollectionRef = collection(firestore, `users/${userId}/cart`);
    const cartQuery = query(cartCollectionRef, where("productId", "==", newItem.productId), where("size", "==", newItem.size));
    const querySnapshot = await getDocs(cartQuery);

    if (querySnapshot.empty) {
      // Add new item if it doesn't exist in the cart
      await addDoc(cartCollectionRef, newItem);
    } else {
      // Update quantity if the item exists
      querySnapshot.forEach(async (cartDoc) => {
        const itemRef = cartDoc.ref;
        const updatedQuantity = cartDoc.data().quantity + newItem.quantity;
        await updateDoc(itemRef, { quantity: updatedQuantity });
      });
    }
  };

  // Remove an item from the cart
  const removeFromCart = async (productId, size) => {
    const cartCollectionRef = collection(firestore, `users/${userId}/cart`);
    const cartQuery = query(cartCollectionRef, where("productId", "==", productId), where("size", "==", size));
    const querySnapshot = await getDocs(cartQuery);

    querySnapshot.forEach(async (cartDoc) => {
      await cartDoc.ref.delete(); // Remove the item from the cart
    });

    setCartItems(cartItems.filter(item => !(item.productId === productId && item.size === size)));
  };

  // Get the total quantity of items in the cart
  const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  // Provide cart functions and state globally
  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, cartCount }}>
      {children}
    </CartContext.Provider>
  );
};

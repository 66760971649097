import React, { useState, useContext, useEffect } from 'react';
import { useUser } from './UserContext'; // Use the custom hook
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { app } from './firebaseConfig';
import './GenerateLink.css'; // Importing the CSS for styling
import './CustomCookieConsent.css'; // Importing the CSS for styling
import Cookies from 'js-cookie';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GenerateLink = () => {
  const { user } = useContext(useUser);
  const [links, setLinks] = useState([]);
  const [commission, setCommission] = useState(0);
  const [commissionHistory, setCommissionHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [commissionRate, setCommissionRate] = useState(0);

  useEffect(() => {
    const fetchAffiliateData = async () => {
      const db = getFirestore(app);
      const docRef = doc(db, 'users', user.uid, 'affiliate', 'data');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLinks(data.links || []);
        setCommission(data.commission || 0);
        setCommissionHistory(data.commissionHistory || []);
      }
      setLoading(false);
    };

    fetchAffiliateData();
  }, [user.uid]);

  const openModal = () => {
    if (links.length < 5) {
      setModalIsOpen(true);
    } else {
      toast.error('You can only generate up to 5 links.');
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const generateLinkAndCode = async (e) => {
    e.preventDefault();
    if (discountRate + commissionRate > 10) {
      toast.error('The total of discount rate and commission rate cannot exceed 10%.');
      return;
    }

    const newLinkId = `link${links.length + 1}`;
    const newLink = `https://lesmokeu.com/?ref=${user.uid}&link=${newLinkId}`;
    const newCode = `AFF-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;

    const newLinkData = {
      link: newLink,
      code: newCode,
      discountRate,
      commissionRate,
    };

    const updatedLinks = [...links, newLinkData];
    setLinks(updatedLinks);

    const db = getFirestore(app);
    const docRef = doc(db, 'users', user.uid, 'affiliate', 'data');

    // Check if the document exists before updating it
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        links: updatedLinks,
        commission: 0,
        commissionHistory: [],
      });
    } else {
      await setDoc(docRef, {
        links: updatedLinks,
        commission: 0,
        commissionHistory: [],
      });
    }

    // Set cookie for referral tracking
    Cookies.set('affiliate_ref', user.uid, { expires: 1 }); // Expires in 1 day
    setModalIsOpen(false);
    toast.success('Affiliate link and code generated successfully.');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const commissionData = {
    labels: commissionHistory.map(entry => entry.date),
    datasets: [
      {
        label: 'Commission Over Time',
        data: commissionHistory.map(entry => entry.amount),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <ToastContainer />
      <h1>Generate Affiliate Link and Code</h1>
      <p>Welcome to the exclusive club of affiliates for our brand. You have everything you need to start promoting and earning commissions. You can generate up to 5 unique links, each with customizable discount and commission rates. Use these links to attract customers and track your earnings. Remember, you can adjust the discount and commission rates as long as their total is 10%.</p>
      <div className="generate-link">
        {!loading && (
          <button className="generate-button" onClick={openModal}>
            Generate Affiliate Link and Code
          </button>
        )}
        {links.map((linkData, index) => (
          <div key={index} className="link-card">
            <div className="link-section">
              <h3>Link</h3>
              <div className="link-item" onClick={() => copyToClipboard(linkData.link)}>
                {linkData.link}
                <button className="copy-button">Copy</button>
              </div>
            </div>
            <div className="link-section">
              <h3>Code</h3>
              <div className="link-item">
                {linkData.code}
                <button className="copy-button" onClick={() => copyToClipboard(linkData.code)}>Copy</button>
              </div>
            </div>
            <div className="link-section">
              <h3>Discount Rate</h3>
              <div className="link-item">
                {linkData.discountRate}%
              </div>
            </div>
            <div className="link-section">
              <h3>Commission Rate</h3>
              <div className="link-item">
                {linkData.commissionRate}%
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="graph-item-2">
        <h2>Commission Over Time</h2>
        <div className="chart-container">
          <Line data={commissionData} options={{ scales: { y: { beginAtZero: true } } }} />
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Set Rates" className="cookie-consent-modal" overlayClassName="modal-backdrop">
        <h2>Set Discount and Commission Rates</h2>
        <form onSubmit={generateLinkAndCode}>
          <label>
            Discount Rate:
            <input
              type="number"
              min="0"
              max="10"
              value={discountRate}
              onChange={(e) => setDiscountRate(Number(e.target.value))}
              required
            />
          </label>
          <label>
            Commission Rate:
            <input
              type="number"
              min="0"
              max="10"
              value={commissionRate}
              onChange={(e) => setCommissionRate(Number(e.target.value))}
              required
            />
          </label>
          <div className="modal-actions">
            <button type="submit" className="accept-button-2">Confirm</button>
            <button type="button" onClick={closeModal} className="decline-button-2">Cancel</button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default GenerateLink;
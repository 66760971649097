import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, setDoc, getDoc, query, orderBy, where } from 'firebase/firestore';
import { Modal, Button, message, DatePicker } from 'antd';
import { firestore } from './firebaseConfig';
import './SalesAnalysis.css';
import { parseISO } from 'date-fns';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SalesAnalysis = () => {
  const [salesData, setSalesData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [totalSales, setTotalSales] = useState(0);
  const [usersData, setUsersData] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [showPendingOnly, setShowPendingOnly] = useState(false);
  const [showTodayOnly, setShowTodayOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUsersData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(usersData).length > 0) {
      fetchSalesData();
    }
  }, [usersData, dateRange, showPendingOnly, showTodayOnly]);

  const fetchUsersData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "users"));
    const users = {};
    querySnapshot.docs.forEach(doc => {
      users[doc.id] = doc.data().email;
    });
    setUsersData(users);
  };

  const fetchSalesData = async () => {
    let salesQuery = query(collection(firestore, "sales"), orderBy("timestamp", "desc"));
    if (dateRange.length > 0) {
      salesQuery = query(salesQuery, 
        where('timestamp', '>=', dateRange[0].startOf('day').toDate()), 
        where('timestamp', '<=', dateRange[1].endOf('day').toDate())
      );
    }
    if (showPendingOnly) {
      salesQuery = query(salesQuery, where('status', '==', 'Pending'));
    }
    if (showTodayOnly) {
      const startOfToday = moment().startOf('day').toISOString();
      const endOfToday = moment().endOf('day').toISOString();
      salesQuery = query(salesQuery, 
        where('timestamp', '>=', startOfToday), 
        where('timestamp', '<=', endOfToday)
      );
    }
    const querySnapshot = await getDocs(salesQuery);
    const sales = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const formattedTimestamp = new Date(data.timestamp).toLocaleString();
  
      return {
        id: doc.id,
        ...data,
        timestamp: formattedTimestamp,
        userId: usersData[data.userId] || data.userId,
      };
    });
  
    const total = sales.reduce((sum, record) => sum + parseFloat(record.amount), 0);
    setSalesData(sales);
    setTotalSales(total.toFixed(2));
  };

  const showModal = (sale) => {
    setSelectedSale(sale);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirmPurchase = async () => {
    if (selectedSale) {
      const saleRef = doc(firestore, "sales", selectedSale.id);
      await updateDoc(saleRef, {
        status: "Confirmed"
      });

      const updatedSale = await getDoc(saleRef);
      if (updatedSale.exists() && updatedSale.data().status === "Confirmed") {
        const orderData = {
          ...updatedSale.data(),
          status: "Order Pending"
        };

        const orderRef = doc(firestore, "orders", updatedSale.id);
        await setDoc(orderRef, orderData);
        message.success('Venta movida a órdenes con estado Orden Pendiente.');

        fetchSalesData();
        setIsModalVisible(false);
      } else {
        message.error("No se pudo actualizar el estado de la venta. Por favor, inténtelo de nuevo.");
      }
    }
  };

  return (
    <div className="crm-container">
      <div className="header-container">
        <h2 className="title">Datos de Ventas</h2>
        <div className="total-sales">
          Ventas Totales: ${totalSales}
        </div>
      </div>
      <div className="filters-container">
        <Button onClick={() => setShowPendingOnly(!showPendingOnly)}>
          {showPendingOnly ? "Mostrar Todas las Ventas" : "Mostrar Solo Pendientes"}
        </Button>
        <Button onClick={() => setShowTodayOnly(!showTodayOnly)}>
          {showTodayOnly ? "Mostrar Todas las Ventas" : "Mostrar Solo Hoy"}
        </Button>
        <RangePicker onChange={(dates) => setDateRange(dates)} />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID De Transacción</th>
              <th>Email Del Usuario</th>
              <th>Monto</th>
              <th>Método De Pago</th>
              <th>Marca De Tiempo</th>
              <th>Estado</th>
              <th>Detalles</th>
            </tr>
          </thead>
          <tbody>
            {salesData.map((sale) => (
              <tr key={sale.id}>
                <td>{sale.transactionId}</td>
                <td>{sale.userId}</td>
                <td>${sale.amount}</td>
                <td>{sale.paymentMethod}</td>
                <td>{sale.timestamp}</td>
                <td>{sale.status}</td>
                <td><Button onClick={() => showModal(sale)}>Ver</Button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedSale && (
        <Modal
          title="Detalles de la Venta"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>Regresar</Button>,
            <Button key="submit" type="primary" onClick={confirmPurchase}>
              Confirmar Compra
            </Button>
          ]}
        >
          <p><strong>ID de Transacción:</strong> {selectedSale.transactionId}</p>
          <p><strong>Email del Usuario:</strong> {selectedSale.userId}</p>
          <p><strong>Monto:</strong> ${selectedSale.amount}</p>
          <p><strong>Método de Pago:</strong> {selectedSale.paymentMethod}</p>
          <p><strong>Marca de Tiempo:</strong> {selectedSale.timestamp}</p>
          <p><strong>Estado:</strong> {selectedSale.status}</p>
          <p><strong>Artículos:</strong></p>
          {selectedSale.items.map((item, index) => (
            <div key={index}>
              <p>Nombre: {item.name}</p>
              <p>Precio: ${item.price}</p>
              <p>Cantidad: {item.quantity}</p>
              <p>Descripción: {item.description}</p>
              <img src={item.imageUrls[0]} alt={item.name} style={{ width: "100px" }} />
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default SalesAnalysis;

import { FiShoppingBag, FiUser, FiCalendar, FiHeadphones } from 'react-icons/fi'; // Feather icons
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import loginImage from './Signup.jpeg'; // Your login image here

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Login successful! Redirecting...');
      navigate('/shop');
    } catch (error) {
      toast.error(`Login failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = () => {
    setLoading(true);
    signInWithRedirect(auth, googleProvider)
      .catch((error) => {
        toast.error(`Google sign-in failed: ${error.message}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-image-container">
          <img src={loginImage} alt="Login Visual" className="login-image" />
          <div className="login-text-overlay">
            <h1>Welcome Back!</h1>
          </div>
        </div>

        <div className="login-form-container">
          <form onSubmit={handleLogin} className="login-form">
            <h2 className="login-title">Log In</h2>

            <div className="email-password-fields">
              <input
                type="email"
                className="login-input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                className="login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="login-buttons">
              <button type="submit" className="login-button" disabled={loading}>
                {loading ? 'Logging In...' : 'Log In'}
              </button>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="login-button google"
                disabled={loading}
              >
                {loading ? 'Signing In...' : 'Sign in with Google'}
              </button>
            </div>

            <p onClick={() => navigate('/signup')} className="toggle-auth">
              Don't have an account? Sign up
            </p>

            <div className="login-benefits">
              <div className="benefits-column">
                <h3>Shopping Benefits</h3>
                <div className="benefit-item">
                  <FiShoppingBag className="benefit-icon" />
                  <p>Check the details and monitor the status of your orders and returns</p>
                </div>
                <div className="benefit-item">
                  <FiUser className="benefit-icon" />
                  <p>Create a wishlist to save your favorite items</p>
                </div>
              </div>

              <div className="benefits-column">
                <h3>Customer Services</h3>
                <div className="benefit-item">
                  <FiCalendar className="benefit-icon" />
                  <p>View your private appointments and repair requests</p>
                </div>
                <div className="benefit-item">
                  <FiHeadphones className="benefit-icon" />
                  <p>Receive tailored assistance from our Client Service</p>
                </div>
              </div>
            </div>
          </form>
        </div>

        <ToastContainer position="top-center" autoClose={5000} />
      </div>

    </div>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import { Button, Card, message, Popconfirm, Carousel, Modal, Form, Input, Select, Upload } from 'antd';
import { getDocs, collection, deleteDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { firestore, storage } from './firebaseConfig';
import { PlusOutlined } from '@ant-design/icons';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { QRCodeCanvas as QRCode } from 'qrcode.react';
import { v4 as uuidv4 } from 'uuid'; // Import UUID function
import './BaseProductManagement.css';  // Ensure this file contains appropriate styling

const { Option } = Select;

const JewelryProductManagement = () => {
    const [jewelryItems, setJewelryItems] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentJewelry, setCurrentJewelry] = useState(null);
    const [form] = Form.useForm();
    const [uploading, setUploading] = useState(false);
    const [imageFiles, setImageFiles] = useState([]);

    useEffect(() => {
        const fetchJewelryItems = async () => {
            const querySnapshot = await getDocs(collection(firestore, "jewelry"));
            const items = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setJewelryItems(items);
        };

        fetchJewelryItems();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(firestore, "jewelry", id));
            setJewelryItems(jewelryItems.filter(item => item.id !== id));
            message.success('Jewelry item successfully deleted!');
        } catch (error) {
            message.error('Failed to delete the jewelry item: ' + error.message);
        }
    };

    const handleEdit = (item) => {
        setCurrentJewelry(item);
        form.setFieldsValue(item);
        setImageFiles(item.imageUrls.map((url, index) => ({
            uid: index,
            name: `image-${index}`,
            status: 'done',
            url: url,
        })));
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentJewelry(null);
        form.resetFields();
        setImageFiles([]);
    };

    const handleSave = async (values) => {
        setUploading(true);
        try {
            const updatedJewelry = { ...currentJewelry, ...values };
            const newImageFiles = imageFiles.filter(file => file.originFileObj);

            // Upload new images if any
            if (newImageFiles.length > 0) {
                const newImageUrls = await Promise.all(newImageFiles.map(async (file) => {
                    const imageRef = storageRef(storage, `jewelry/${file.name}-${Date.now()}`);
                    const snapshot = await uploadBytes(imageRef, file.originFileObj);
                    return getDownloadURL(snapshot.ref);
                }));

                // Combine old URLs and new uploaded URLs
                const existingUrls = imageFiles.filter(file => !file.originFileObj).map(file => file.url);
                updatedJewelry.imageUrls = [...existingUrls, ...newImageUrls];
            } else {
                updatedJewelry.imageUrls = imageFiles.map(file => file.url);
            }

            // Remove the custom file objects from the values before saving to Firestore
            delete updatedJewelry.images;

            await updateDoc(doc(firestore, "jewelry", currentJewelry.id), updatedJewelry);
            setJewelryItems(jewelryItems.map(item => item.id === currentJewelry.id ? updatedJewelry : item));
            message.success('Jewelry item successfully updated!');
            handleCancel();
        } catch (error) {
            message.error('Failed to update the jewelry item: ' + error.message);
        } finally {
            setUploading(false);
        }
    };

    const handleImageChange = ({ fileList }) => {
        setImageFiles(fileList);
    };

    const moveImage = (index, direction) => {
        const newImageFiles = [...imageFiles];
        const targetIndex = index + direction;
        if (targetIndex >= 0 && targetIndex < newImageFiles.length) {
            const temp = newImageFiles[targetIndex];
            newImageFiles[targetIndex] = newImageFiles[index];
            newImageFiles[index] = temp;
            setImageFiles(newImageFiles);
        }
    };

    const removeImage = (index) => {
        const newImageFiles = imageFiles.filter((_, i) => i !== index);
        setImageFiles(newImageFiles);
    };

    return (
        <div className="base-product-container">
            <h1>Jewelry Products</h1>
        <div className="product-container">
                {jewelryItems.map(item => (
                    <Card
                        key={item.id}
                        title={item.name}
                        extra={
                            <>
                                <Button type="primary" onClick={() => handleEdit(item)}>Edit</Button>
                                <Popconfirm
                                    title="Are you sure you want to delete this jewelry item?"
                                    onConfirm={() => handleDelete(item.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="danger" className="delete-button">Delete</Button>
                                </Popconfirm>
                            </>
                        }
                        className="product-card"
                    >
                        <p className="product-header"><strong>Type:</strong> {item.category}</p>
                        <div className="product-content">
                            <p><strong>Description:</strong> {item.shortDescription}</p>
                            {item.imageUrls && item.imageUrls.length > 0 && (
                                <Carousel autoplay className="product-carousel">
                                    {item.imageUrls.map((url, index) => (
                                        <div key={index} className="carousel-item">
                                            <img src={url} alt={`Jewelry ${index}`} style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                            <div className="qr-code-container">
                                <QRCode value={`https://lesmokeu.com/jewelry/${item.id}`} size={128} level="H" />
                            </div>
                        </div>
                    </Card>
                ))}
    
            <Modal
                title="Edit Jewelry Item"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="edit-product-modal"
            >
                <Form form={form} layout="vertical" onFinish={handleSave}>
                    <Form.Item label="Category" name="category" rules={[{ required: true }]}>
                        <Select placeholder="Select a category">
                            <Option value="ring">Ring</Option>
                            <Option value="chain">Chain</Option>
                            <Option value="necklace">Necklace</Option>
                            <Option value="earrings">Earrings</Option>
                            <Option value="others">Others</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input placeholder="Enter the product name" />
                    </Form.Item>
                    <Form.Item label="Short Description" name="shortDescription" rules={[{ required: true }]}>
                        <Input.TextArea rows={2} placeholder="Enter the short description" />
                    </Form.Item>
                    <Form.Item label="Long Description" name="longDescription" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} placeholder="Enter the long description" />
                    </Form.Item>
                    <Form.Item label="Price" name="price" rules={[{ required: true }]}>
                        <Input placeholder="Enter the price" prefix="$" type="number" />
                    </Form.Item>
                    <Form.Item label="Quantity" name="quantity" rules={[{ required: true }]}>
                        <Input placeholder="Enter the quantity" type="number" />
                    </Form.Item>
                    <Form.Item label="Cost" name="cost" rules={[{ required: true }]}>
                        <Input placeholder="Enter the cost" prefix="$" type="number" />
                    </Form.Item>
                    <Form.Item label="Margin" name="margin">
                        <Input placeholder="Margin" prefix="%" readOnly />
                    </Form.Item>
                    <Form.Item label="Images" name="images">
                        <Upload
                            listType="picture-card"
                            fileList={imageFiles}
                            onPreview={file => {
                                const src = file.url;
                                const imgWindow = window.open(src, '_blank');
                                imgWindow.document.write(`<img src="${src}" style="width:100%;">`);
                            }}
                            onChange={handleImageChange}
                            beforeUpload={() => false}
                            multiple
                        >
                            {imageFiles.length >= 8 ? null : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                        </Upload>
                        <div className="image-management">
                            {imageFiles.map((file, index) => (
                                <div key={index} className="image-item">
                                    <img src={file.url} alt={`image-${index}`} style={{ width: '100px', height: '100px' }} />
                                    <div className="image-controls">
                                        <Button onClick={() => moveImage(index, -1)} disabled={index === 0}>Up</Button>
                                        <Button onClick={() => moveImage(index, 1)} disabled={index === imageFiles.length - 1}>Down</Button>
                                        <Button onClick={() => removeImage(index)}>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                    <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
                        <Input placeholder="Enter the product SKU" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={uploading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
        </div>
    );
};

export default JewelryProductManagement;

import React, { useContext } from 'react';
import './Footer.css';
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter, AiOutlineYoutube } from 'react-icons/ai';
import LanguageSwitcher from '../LanguageSwitcher'; // Import the Language Switcher
import { LanguageContext } from '../LanguageContext'; // Import Language Context

const Footer = () => {
  const { language } = useContext(LanguageContext); // Use the language from the LanguageContext

  // Define translations for the Footer
  const translations = {
    en: {
      subscribeText1: 'Join us on the journey to redefine streetwear elegance.',
      subscribeText2: 'Get exclusive tips, content, and updates from Le Smoke U directly in your inbox.',
      subscribePlaceholder: 'E-mail address *',
      subscribeButton: 'SUBSCRIBE',
      subscribeNote: 'By subscribing, you confirm you’ve read our Privacy Statement and agree to receive marketing emails.',
      contacts: 'CONTACTS',
      writeUs: 'WRITE US ON WHATSAPP',
      support: 'SUPPORT',
      services: 'LE SMOKE U SERVICES',
      trackOrder: 'TRACK YOUR ORDER',
      faqs: 'FAQs',
      returns: 'RETURNS',
      company: 'COMPANY',
      about: 'Le Smoke U LLC',
      sustainability: 'SUSTAINABILITY',
      workWithUs: 'WORK WITH US',
      legal: 'LEGAL',
      legalNotice: 'LEGAL NOTICE',
      privacyPolicy: 'PRIVACY POLICY',
      cookiePolicy: 'COOKIE POLICY',
      terms: 'PURCHASE TERMS',
    },
    es: {
      subscribeText1: 'Únase a nosotros en el viaje para redefinir la elegancia del streetwear.',
      subscribeText2: 'Reciba consejos exclusivos, contenido y actualizaciones de Le Smoke U directamente en su bandeja de entrada.',
      subscribePlaceholder: 'Dirección de correo electrónico *',
      subscribeButton: 'SUSCRIBIRSE',
      subscribeNote: 'Al suscribirse, confirma que ha leído nuestra Declaración de Privacidad y acepta recibir correos electrónicos de marketing.',
      contacts: 'CONTACTOS',
      writeUs: 'ESCRÍBANOS EN WHATSAPP',
      support: 'SOPORTE',
      services: 'SERVICIOS DE LE SMOKE U',
      trackOrder: 'RASTREE SU PEDIDO',
      faqs: 'PREGUNTAS FRECUENTES',
      returns: 'DEVOLUCIONES',
      company: 'EMPRESA',
      about: 'Le Smoke U LLC',
      sustainability: 'SOSTENIBILIDAD',
      workWithUs: 'TRABAJE CON NOSOTROS',
      legal: 'LEGAL',
      legalNotice: 'AVISO LEGAL',
      privacyPolicy: 'POLÍTICA DE PRIVACIDAD',
      cookiePolicy: 'POLÍTICA DE COOKIES',
      terms: 'TÉRMINOS DE COMPRA',
    },
  };

  const t = translations[language]; // Use the selected language for translations

  return (
    <footer className="footer">
      <div className="footer-brand">
        <h1>LE SMOKE U</h1>
      </div>

      <div className="footer-content">
        <div className="footer-section footer-subscribe">
          <p>{t.subscribeText1}</p>
          <p>{t.subscribeText2}</p>
          <form className="subscribe-form">
            <input type="email" placeholder={t.subscribePlaceholder} />
            <button type="submit">{t.subscribeButton}</button>
          </form>
          <p>{t.subscribeNote}</p>
        </div>

        <div className="footer-section">
          <h4>{t.contacts}</h4>
          <ul>
            <li><a href="https://wa.me/">{t.writeUs}</a></li>
            <li><a href="/contacts">{t.contacts}</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>{t.support}</h4>
          <ul>
            <li><a href="/services">{t.services}</a></li>
            <li><a href="/orders">{t.trackOrder}</a></li>
            <li><a href="/faqs">{t.faqs}</a></li>
            <li><a href="/returns">{t.returns}</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>{t.company}</h4>
          <ul>
            <li><a href="/about">{t.about}</a></li>
            <li><a href="/sustainability">{t.sustainability}</a></li>
            <li><a href="/affiliate">{t.workWithUs}</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>{t.legal}</h4>
          <ul>
            <li><a href="/legal-notice">{t.legalNotice}</a></li>
            <li><a href="/privacy">{t.privacyPolicy}</a></li>
            <li><a href="/cookies">{t.cookiePolicy}</a></li>
            <li><a href="/terms">{t.terms}</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-follow-section">
        <LanguageSwitcher />
        <div className="social-icons">
          <a href="https://facebook.com" aria-label="Facebook" className="social-icon"><AiOutlineFacebook /></a>
          <a href="https://instagram.com" aria-label="Instagram" className="social-icon"><AiOutlineInstagram /></a>
          <a href="https://twitter.com" aria-label="Twitter" className="social-icon"><AiOutlineTwitter /></a>
          <a href="https://youtube.com" aria-label="YouTube" className="social-icon"><AiOutlineYoutube /></a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Le Smoke U. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton'; // Skeleton package for loading effect
import 'react-loading-skeleton/dist/skeleton.css'; // Skeleton default styles
import './Cart.css';

const Cart = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (!user) {
      message.info("Please log in to view your cart.");
      navigate('/login'); // Redirect to login or signup page
      return;
    }

    const cartCollectionRef = collection(firestore, `users/${user.uid}/cart`);
    const unsubscribe = onSnapshot(cartCollectionRef, (snapshot) => {
      const items = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCartItems(items);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const handleRemoveItem = async (itemId) => {
    if (!user) return;
    const itemDocRef = doc(firestore, `users/${user.uid}/cart`, itemId);
    await deleteDoc(itemDocRef);
    message.success("Item removed from cart.");
  };

  const handleRemoveOneQuantity = async (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      const itemDocRef = doc(firestore, `users/${user.uid}/cart`, itemId);
      await updateDoc(itemDocRef, { quantity: currentQuantity - 1 });
      message.success("Item quantity decreased by one.");
    } else {
      handleRemoveItem(itemId);
    }
  };

  const calculateTotal = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
  };

  const handleCheckout = () => {
    const itemsData = cartItems.map((item) => ({
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      image: item.imageUrls[0], // Make sure to pass the image URL for use in checkout
    }));
    navigate('/checkout', { state: { cartItems: itemsData, subtotal: calculateTotal() } });
  };

  return (
    <>
      <Helmet>
        <title>Your Cart - LE SMOKE U</title>
        <meta name="description" content="Review your shopping cart items and proceed to checkout at LE SMOKE U." />
      </Helmet>
      <div className="cart-wrapper">
        {loading ? (
          <div className="cart-left">
            <Skeleton height={35} width={200} className="cart-header-skeleton" />
            <div className="cart-items-container">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="cart-item-skeleton">
                  <Skeleton width={350} height={250} className="item-image-skeleton" />
                  <div className="item-details-skeleton">
                    <Skeleton width={180} height={20} className="item-name-skeleton" />
                    <Skeleton width={80} height={20} className="item-price-skeleton" />
                    <Skeleton width={100} height={20} className="item-size-skeleton" />
                    <Skeleton width={100} height={20} className="item-quantity-skeleton" />
                    <Skeleton width={80} height={20} className="item-actions-skeleton" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="cart-left">
              <h1 className="cart-header">Shopping bag ({cartItems.length})</h1>
              <div className="cart-items-container">
                {cartItems.map((item) => (
                  <div key={item.id} className="cart-item">
                    <div className="item-image-container">
                      <img src={item.imageUrls[0]} alt={item.name} className="item-image" />
                    </div>
                    <div className="item-details">
                      <div className="item-header">
                        <div className="item-name">{item.name}</div>
                        <div className="item-price">${item.price.toFixed(2)}</div>
                      </div>
                      <div className="item-size">Size: {item.size}</div>
                      <div className="item-quantity">Quantity: {item.quantity}</div>
                      <div className="item-actions">
                        <button className="edit-button">Edit</button>
                        <button className="remove-button" onClick={() => handleRemoveItem(item.id)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="cart-right">
              <div className="cart-summary">
                <div className="cart-summary-item">
                  <span>Subtotal</span>
                  <span>${calculateTotal()}</span>
                </div>
                <button className="checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
                <div className="cart-extra-info">
                  <p>Need help? Contact us</p>
                  <p>Free shipping and returns</p>
                  <p>Secure payments</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { query, where, getDocs, collection, deleteDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import './CheckoutConfirmation.css';

const CheckoutConfirmation = () => {
  const { state } = useLocation();
  const { orderNumber } = state || {};
  const { user } = useUser();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (user && orderNumber) {
        try {
          // Query Firestore for the document where orderNumber matches
          const ordersRef = collection(firestore, `users/${user.uid}/orders`);
          const q = query(ordersRef, where('orderNumber', '==', orderNumber));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Assuming order numbers are unique
            setOrderData({ id: doc.id, ...doc.data() });

            // Now remove cart items since the order is confirmed
            const cartRef = collection(firestore, `users/${user.uid}/cart`);
            const cartSnapshot = await getDocs(cartRef);
            cartSnapshot.forEach(async (cartItem) => {
              await deleteDoc(cartItem.ref);
            });
          } else {
            console.error("Order not found");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching order:", error);
          setLoading(false);
        }
      }
    };

    fetchOrderData();
  }, [user, orderNumber]);

  if (!orderData) {
    return <div>Loading order details...</div>;
  }

  return (
    <div className="checkout-confirmation-container">
      <div className="checkout-confirmation-header">
        <h2 className="checkout-confirmation-title">Order Confirmation</h2>
        <p className="checkout-confirmation-message">Thank you for your order! Your order number is {orderData.orderNumber}.</p>
      </div>

      <div className="checkout-summary-container">
        <h3 className="checkout-summary-title">Order Details</h3>
        <div className="checkout-summary-item">
          <div className="checkout-summary-label">Order Number:</div>
          <div className="checkout-summary-value">{orderData.orderNumber}</div>
        </div>
        <div className="checkout-summary-item">
          <div className="checkout-summary-label">Order Date:</div>
          <div className="checkout-summary-value">{new Date(orderData.timestamp?.toDate()).toLocaleDateString()}</div>
        </div>
        <div className="checkout-summary-item">
          <div className="checkout-summary-label">Payment Method:</div>
          <div className="checkout-summary-value">{orderData.paymentMethod}</div>
        </div>
        <div className="checkout-summary-item">
          <div className="checkout-summary-label">Shipping Address:</div>
          <div className="checkout-summary-value">
            {`${orderData.firstName} ${orderData.lastName}, ${orderData.address}, ${orderData.city}, ${orderData.state}, ${orderData.zipCode}, ${orderData.country}`}
          </div>
        </div>
        <div className="checkout-summary-item">
          <div className="checkout-summary-label">Status:</div>
          <div className="checkout-summary-value">{orderData.status}</div>
        </div>
      </div>

      <div className="checkout-thank-you-message">
        <p>We’ve sent a confirmation email to {orderData.email}. If you have any questions about your order, feel free to contact us at support@shop.com.</p>
        <button onClick={() => navigate('/shop')}>Continue Shopping</button>
      </div>
    </div>
  );
};

export default CheckoutConfirmation;

import React, { useState, useEffect } from 'react';
import { Button, Card, message, Popconfirm, Carousel, Modal, Form, Input, Select, Upload } from 'antd';
import { getDocs, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from './firebaseConfig';
import { PlusOutlined } from '@ant-design/icons';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { QRCodeCanvas as QRCode } from 'qrcode.react';
import './BaseProductManagement.css';  // Ensure this file contains appropriate styling

const { Option } = Select;

const BaseProductManagement = () => {
    const [baseProducts, setBaseProducts] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [form] = Form.useForm();
    const [uploading, setUploading] = useState(false);
    const [imageFiles, setImageFiles] = useState([]);

    useEffect(() => {
        const fetchBaseProducts = async () => {
            const querySnapshot = await getDocs(collection(firestore, "baseProducts"));
            const products = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setBaseProducts(products);
        };

        fetchBaseProducts();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(firestore, "baseProducts", id));
            setBaseProducts(baseProducts.filter(product => product.id !== id));
            message.success('Product successfully deleted!');
        } catch (error) {
            message.error('Failed to delete the product: ' + error.message);
        }
    };

    const handleEdit = (product) => {
        setCurrentProduct(product);
        form.setFieldsValue(product);
        setImageFiles(product.imageUrls.map((url, index) => ({
            uid: index,
            name: `image-${index}`,
            status: 'done',
            url: url,
        })));
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentProduct(null);
        form.resetFields();
        setImageFiles([]);
    };

    const handleSave = async (values) => {
        setUploading(true);
        try {
            const updatedProduct = { ...currentProduct, ...values };
            const newImageFiles = imageFiles.filter(file => file.originFileObj);

            // Upload new images if any
            if (newImageFiles.length > 0) {
                const newImageUrls = await Promise.all(newImageFiles.map(async (file) => {
                    const imageRef = storageRef(storage, `baseProducts/${file.name}-${Date.now()}`);
                    const snapshot = await uploadBytes(imageRef, file.originFileObj);
                    return getDownloadURL(snapshot.ref);
                }));

                // Combine old URLs and new uploaded URLs
                const existingUrls = imageFiles.filter(file => !file.originFileObj).map(file => file.url);
                updatedProduct.imageUrls = [...existingUrls, ...newImageUrls];
            } else {
                updatedProduct.imageUrls = imageFiles.map(file => file.url);
            }

            // Remove the custom file objects from the values before saving to Firestore
            delete updatedProduct.images;

            await updateDoc(doc(firestore, "baseProducts", currentProduct.id), updatedProduct);
            setBaseProducts(baseProducts.map(product => product.id === currentProduct.id ? updatedProduct : product));
            message.success('Product successfully updated!');
            handleCancel();
        } catch (error) {
            message.error('Failed to update the product: ' + error.message);
        } finally {
            setUploading(false);
        }
    };

    const handleImageChange = ({ fileList }) => {
        setImageFiles(fileList);
    };

    const moveImage = (index, direction) => {
        const newImageFiles = [...imageFiles];
        const targetIndex = index + direction;
        if (targetIndex >= 0 && targetIndex < newImageFiles.length) {
            const temp = newImageFiles[targetIndex];
            newImageFiles[targetIndex] = newImageFiles[index];
            newImageFiles[index] = temp;
            setImageFiles(newImageFiles);
        }
    };

    const removeImage = (index) => {
        const newImageFiles = imageFiles.filter((_, i) => i !== index);
        setImageFiles(newImageFiles);
    };

    return (
        <div className="base-product-container">
            <h1>Base Products</h1>
            <div className="product-container">
                {baseProducts.map(product => (
                    <Card
                        key={product.id}
                        title={product.name}
                        extra={
                            <>
                                <Button type="primary" onClick={() => handleEdit(product)}>View Details</Button>
                                <Popconfirm
                                    title="Are you sure you want to delete this product?"
                                    onConfirm={() => handleDelete(product.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="danger" className="delete-button">Delete</Button>
                                </Popconfirm>
                            </>
                        }
                        className="product-card"
                    >
                        <p className="product-header"><strong>Category:</strong> {product.category}</p>
                        <div className="product-content">
                            <p><strong>Description:</strong> {product.shortDescription}</p>
                            {product.imageUrls && product.imageUrls.length > 0 && (
                                <Carousel autoplay className="product-carousel">
                                    {product.imageUrls.map((url, index) => (
                                        <div key={index} className="carousel-item">
                                            <img src={url} alt={`Product ${index}`} style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                            <div className="qr-code-container">
                                <QRCode value={`https://lesmokeu.com/product/${product.id}`} size={128} level="H" />
                            </div>
                        </div>
                    </Card>
                ))}
            </div>

            <Modal
                title="Edit Product"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="edit-product-modal"
            >
                <Form form={form} layout="vertical" onFinish={handleSave}>
                    <Form.Item label="Categoría del Producto" name="category" rules={[{ required: true }]}>
                        <Select placeholder="Seleccione una categoría">
                            <Option value="blazer">Blazer</Option>
                            <Option value="top">Top</Option>
                            <Option value="pantalon">Pantalon</Option>
                            <Option value="falda">Falda</Option>
                            <Option value="dress">Vestido</Option>
                            <Option value="others">Otros</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Nombre del Producto" name="name" rules={[{ required: true }]}>
                        <Input placeholder="Introduce el nombre del producto" />
                    </Form.Item>
                    <Form.Item label="Descripción Corta del Producto" name="shortDescription" rules={[{ required: true }]}>
                        <Input.TextArea rows={2} placeholder="Introduce la descripción corta del producto" />
                    </Form.Item>
                    <Form.Item label="Descripción Larga del Producto" name="longDescription" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} placeholder="Introduce la descripción larga del producto" />
                    </Form.Item>
                    <Form.Item label="Precio" name="price" rules={[{ required: true }]}>
                        <Input placeholder="Introduce el precio" prefix="$" type="number" />
                    </Form.Item>
                    <Form.Item label="Cantidad (Talla S)" name="quantityS" rules={[{ required: true }]}>
                        <Input placeholder="Introduce la cantidad de talla S" type="number" />
                    </Form.Item>
                    <Form.Item label="Cantidad (Talla M)" name="quantityM" rules={[{ required: true }]}>
                        <Input placeholder="Introduce la cantidad de talla M" type="number" />
                    </Form.Item>
                    <Form.Item label="Coste" name="cost" rules={[{ required: true }]}>
                        <Input placeholder="Introduce el coste" prefix="$" type="number" />
                    </Form.Item>
                    <Form.Item label="Margen" name="margin">
                        <Input placeholder="Introduce el margen" prefix="%" readOnly />
                    </Form.Item>
                    <Form.Item label="Imágenes del Producto" name="images">
                        <Upload
                            listType="picture-card"
                            fileList={imageFiles}
                            onPreview={file => {
                                const src = file.url;
                                const imgWindow = window.open(src, '_blank');
                                imgWindow.document.write(`<img src="${src}" style="width:100%;">`);
                            }}
                            onChange={handleImageChange}
                            beforeUpload={() => false}
                            multiple
                        >
                            {imageFiles.length >= 8 ? null : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                        </Upload>
                        <div className="image-management">
                            {imageFiles.map((file, index) => (
                                <div key={index} className="image-item">
                                    <img src={file.url} alt={`image-${index}`} style={{ width: '100px', height: '100px' }} />
                                    <div className="image-controls">
                                        <Button onClick={() => moveImage(index, -1)} disabled={index === 0}>Up</Button>
                                        <Button onClick={() => moveImage(index, 1)} disabled={index === imageFiles.length - 1}>Down</Button>
                                        <Button onClick={() => removeImage(index)}>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                    <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
                        <Input placeholder="Enter the product SKU" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={uploading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default BaseProductManagement;

import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Modal, Button, message, Form, Input } from 'antd';
import { firestore } from './firebaseConfig';
import './UsersAnalysis.css';

const UsersAnalysis = () => {
  const [userData, setUserData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'users'));
      const users = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const formattedCreationTime = data.createdAt?.toDate().toLocaleString('en-US', {
          timeZone: 'America/New_York',
        }) || 'N/A';
        return {
          id: doc.id,
          ...data,
          createdAt: formattedCreationTime,
        };
      });
      setUserData(users);
    } catch (error) {
      message.error('Failed to fetch user data.');
    }
  };

  const showModal = (user) => {
    setSelectedUser(user);
    form.setFieldsValue({
      ...user,
      phoneNumber: user.phoneNumber || '',
      address: user.address || '',
      municipio: user.municipio || '',
      age: user.age || '',
      gender: user.gender || '',
      role: user.role || 'N/A', // Add role to the form fields
    });
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const userRef = doc(firestore, 'users', selectedUser.id);
      await updateDoc(userRef, values);
      message.success('User details updated successfully!');
      fetchUserData(); // Refresh the user data
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to update user details.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filteredUsers = userData.filter((user) =>
    user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="user-analysis-container">
      <div className="user-analysis-header">
        <h2 className="user-analysis-title">User Management</h2>
        <Input
          placeholder="Search by name or email"
          className="user-analysis-search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table className="user-analysis-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Creation Time</th>
            <th>Address</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Role</th> {/* Add a column for role */}
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id} className="user-analysis-row">
              <td>{user.firstName || 'N/A'} {user.lastName || 'N/A'}</td>
              <td>{user.email || 'N/A'}</td>
              <td>{user.phoneNumber || 'N/A'}</td>
              <td>{user.createdAt || 'N/A'}</td>
              <td>{user.address || 'N/A'}</td>
              <td>{user.age || 'N/A'}</td>
              <td>{user.gender || 'N/A'}</td>
              <td>{user.role || 'N/A'}</td> {/* Display user role */}
              <td>
                <Button className="user-analysis-edit-btn" onClick={() => showModal(user)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser && (
        <Modal
          title="Edit User Details"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={600} // Increase modal width for more space
          footer={[
            <Button key="back" onClick={handleCancel}>Return</Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Update
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Please enter the first name' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Please enter the last name' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Phone Number">
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item name="age" label="Age">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="gender" label="Gender">
              <Input />
            </Form.Item>
            <Form.Item name="role" label="Role"> {/* Add a field for editing the role */}
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default UsersAnalysis;

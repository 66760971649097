import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // For meta tags
import { getAuth } from 'firebase/auth';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig'; // Adjust the path to your firebaseConfig
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './Account.css';
import LanguageSwitcher from '../LanguageSwitcher'; // Import the language switcher

const AccountOverview = ({ userData }) => (
  <div className="account-details">
    <h2>Account Overview</h2>
    <p><strong>Name:</strong> {userData?.firstName} {userData?.lastName}</p>
    <p><strong>Email:</strong> {userData?.email}</p>
    <p><strong>Phone Number:</strong> {userData?.phoneNumber || 'N/A'}</p>
    <p><strong>Gender:</strong> {userData?.gender || 'N/A'}</p>
    <p><strong>Age:</strong> {userData?.age || 'N/A'}</p>
    <p><strong>Member Since:</strong> {new Date(userData?.createdAt.seconds * 1000).toLocaleDateString() || 'N/A'}</p>
    <p><strong>Role:</strong> {userData?.role || 'N/A'}</p>
  </div>
);

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchOrders = async () => {
      if (user) {
        const ordersRef = collection(firestore, `users/${user.uid}/orders`);
        const querySnapshot = await getDocs(ordersRef);
        const fetchedOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(fetchedOrders);
      }
    };

    fetchOrders();
  }, [user]);

  if (orders.length === 0) {
    return <p>No orders found</p>;
  }

  return (
    <div className="account-orders">
      <h2>Your Orders</h2>
      {orders.map(order => (
        <div key={order.id} className="order-item">
          <p><strong>Order Number:</strong> {order.orderNumber}</p>
          <p><strong>Date:</strong> {new Date(order.timestamp?.toDate()).toLocaleDateString()}</p>
          <p><strong>Status:</strong> {order.status}</p>
          <p><strong>Total Paid:</strong> {order.paid ? 'Yes' : 'No'}</p>
          <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
          <p><strong>Shipping Address:</strong> {`${order.firstName} ${order.lastName}, ${order.address}, ${order.city}, ${order.state}, ${order.zipCode}, ${order.country}`}</p>

          <h3>Items:</h3>
          <div className="order-items">
            {order.items.map((item, index) => (
              <div key={index} className="order-item-details compact">
                <img
                  src={item.imageUrls[0]} // Show only the first image
                  alt={item.name}
                  className="order-item-image compact-image"
                />
                <div className="order-item-info">
                  <p><strong>{item.name}</strong></p>
                  <p>Price: ${item.price.toFixed(2)}</p>
                  <p>Qty: {item.quantity}</p>
                  <p>Size: {item.size}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const Addresses = ({ userData }) => (
  <div className="account-addresses">
    <h2>Addresses</h2>
    <div className="billing-address">
      <h3>Billing Address</h3>
      <p>{userData?.billingAddress || 'No billing address found'}</p>
      <button className="account-button">Add Billing Address</button>
    </div>
    <div className="shipping-address">
      <h3>Shipping Address</h3>
      <p>{userData?.shippingAddress || 'No shipping address found'}</p>
      <button className="account-button">Add Shipping Address</button>
    </div>
  </div>
);

const Settings = ({ userData }) => (
  <div className="account-settings">
    <h2>Settings</h2>
    <div className="setting-pair">
      <div className="language-setting">
        <h3>Language</h3>
        <LanguageSwitcher />
      </div>
      <div className="timezone-setting">
        <h3>Time Zone</h3>
        <select
          value={userData?.timezone || 'GMT'}
          onChange={(e) => console.log('Change timezone to:', e.target.value)}
        >
          <option value="GMT">GMT</option>
          <option value="CET">CET</option>
          <option value="EST">EST</option>
        </select>
      </div>
    </div>
    <div className="setting-pair">
      <div className="notifications-setting">
        <h3>Notifications</h3>
        <label>
          <input type="checkbox" checked={userData?.notifications || false} />
          Receive email notifications
        </label>
      </div>
      <div className="cookies-setting">
        <h3>Cookies Consent</h3>
        <label>
          <input type="checkbox" checked={userData?.AnalyticsConsent || false} />
          Allow cookies for analytics
        </label>
      </div>
    </div>
  </div>
);

const Account = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData(docSnap.data());
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [auth]);

  useEffect(() => {
    if (!isMobile && (location.pathname === '/account' || location.pathname === '/account/')) {
      navigate('/account/overview');
    }
  }, [location, navigate, isMobile]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Account Overview</title>
        <meta name="description" content="View and manage your account details, orders, addresses, and settings." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div className="account-wrapper">
        {!isMobile && (
          <div className="account-sidebar">
            <ul>
              <li className={location.pathname === '/account/overview' ? 'active' : ''}>
                <Link to="/account/overview">Account Overview</Link>
              </li>
              <li className={location.pathname === '/account/orders' ? 'active' : ''}>
                <Link to="/account/orders">Orders</Link>
              </li>
              <li className={location.pathname === '/account/addresses' ? 'active' : ''}>
                <Link to="/account/addresses">Addresses</Link>
              </li>
              <li className={location.pathname === '/account/settings' ? 'active' : ''}>
                <Link to="/account/settings">Settings</Link>
              </li>
              <li>
  <button
    className="account-logout"
    onClick={() => {
      auth.signOut().then(() => {
        navigate('/'); // Navigate to the home page after logging out
      });
    }}
  >
    LOG OUT
  </button>
</li>
            </ul>
          </div>
        )}

        <div className="account-content">
          {isMobile ? (
            <>
              <AccountOverview userData={userData} />
              <Orders />
              <Addresses userData={userData} />
              <Settings userData={userData} />
            </>
          ) : (
            <Routes>
              <Route path="overview" element={<AccountOverview userData={userData} />} />
              <Route path="orders" element={<Orders />} />
              <Route path="addresses" element={<Addresses userData={userData} />} />
              <Route path="settings" element={<Settings userData={userData} />} />
            </Routes>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;

import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react'; // Ensure you have this library installed
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from './firebaseConfig'; // Adjust this path to your firebase config file
import { v4 as uuidv4 } from 'uuid'; // Ensure uuid is installed to generate unique IDs
import imageCompression from 'browser-image-compression'; // Add this import

const { Option } = Select;

const BaseProduct = () => {
    const [form] = Form.useForm();
    const [baseProduct, setBaseProduct] = useState({
        sku: '',
        category: '',
        name: '',
        shortDescription: '',
        longDescription: '',
        images: [],
        price: 0,
        quantityS: 0,
        quantityM: 0,
        cost: 0,
        margin: 0,
        qrCode: ''
    });
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const calculateMargin = () => {
            const { cost, price } = baseProduct;
            if (cost && price) {
                const margin = ((price - cost) / price) * 100;
                setBaseProduct(prevState => ({
                    ...prevState,
                    margin: margin.toFixed(2) // Margin rounded to 2 decimal places
                }));
                form.setFieldsValue({ margin: margin.toFixed(2) }); // Update the form field value
            }
        };
        calculateMargin();
    }, [baseProduct.cost, baseProduct.price, form]);

    const compressImage = async (imageFile) => {
        const options = {
            maxSizeMB: 2, // Increase the size limit to 2 MB for better quality
            maxWidthOrHeight: 1920, // Allow larger dimensions for better resolution
            useWebWorker: true, // Use web workers for faster compression
            maxIteration: 10, // Increase iterations to balance quality and size
            initialQuality: 0.9 // Start with high quality
        };
        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
            return imageFile; // Return the original if compression fails
        }
    };    
    
    const handleChange = async (info) => {
        const updatedFiles = await Promise.all(
            info.fileList.map(async (file) => {
                const compressedFile = await compressImage(file.originFileObj);
                return {
                    uid: file.uid,
                    name: compressedFile.name,
                    status: file.status,
                    originFileObj: compressedFile,
                    url: file.url || URL.createObjectURL(compressedFile),
                };
            })
        );
        setBaseProduct(prevState => ({ ...prevState, images: updatedFiles }));
    };    

    const uploadImages = async (files) => {
        return Promise.all(files.map(async file => {
            if (!file.originFileObj) {
                return file.url; // Use existing URL if file is already uploaded
            }
            const imageRef = storageRef(storage, `baseProducts/${file.name}-${Date.now()}`);
            const snapshot = await uploadBytes(imageRef, file.originFileObj);
            return getDownloadURL(snapshot.ref);
        }));
    };

    const handleSubmit = async () => {
        setUploading(true);
        try {
            if (baseProduct.images.length === 0) {
                throw new Error("Please upload at least one image.");
            }

            // Calculate margin before sending to DB
            const cost = parseFloat(baseProduct.cost);
            const price = parseFloat(baseProduct.price);
            const margin = ((price - cost) / price) * 100;

            const imageUrls = await uploadImages(baseProduct.images);
            const quantity = parseInt(baseProduct.quantityS, 10) + parseInt(baseProduct.quantityM, 10);
            const id = uuidv4(); // Generate a UUID for the product
            const productData = {
                id,
                sku: baseProduct.sku,
                category: baseProduct.category,
                name: baseProduct.name,
                shortDescription: baseProduct.shortDescription,
                longDescription: baseProduct.longDescription,
                price,
                quantity,
                quantityS: parseInt(baseProduct.quantityS, 10),
                quantityM: parseInt(baseProduct.quantityM, 10),
                cost,
                margin: margin.toFixed(2), // Keep margin to 2 decimal places
                imageUrls
            };

            await setDoc(doc(firestore, "baseProducts", id), productData);
            setBaseProduct(prevState => ({
                ...prevState,
                qrCode: id // Use the document ID as the QR code value
            }));
            message.success('Base product successfully added!');
            form.resetFields();
            setBaseProduct({ sku: '', category: '', name: '', shortDescription: '', longDescription: '', images: [], price: 0, quantityS: 0, quantityM: 0, cost: 0, margin: 0, qrCode: '' });
        } catch (error) {
            console.error("Error adding base product: ", error);
            message.error(`Error adding base product: ${error.message}`);
        } finally {
            setUploading(false);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="form-container">
            <Form.Item label="Category" name="category" rules={[{ required: true }]}>
                <Select placeholder="Select a category" onChange={value => setBaseProduct(prevState => ({
                    ...prevState,
                    category: value
                }))}>
                    <Option value="blazer">Blazer</Option>
                    <Option value="top">Top</Option>
                    <Option value="pantalon">Pantalon</Option>
                    <Option value="falda">Falda</Option>
                    <Option value="dress">Vestido</Option>
                    <Option value="others">Others</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Product Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="Enter the product name" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    name: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Short Description" name="shortDescription" rules={[{ required: true }]}>
                <Input.TextArea rows={2} placeholder="Enter short description" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    shortDescription: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Long Description" name="longDescription" rules={[{ required: true }]}>
                <Input.TextArea rows={4} placeholder="Enter long description" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    longDescription: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Price" name="price" rules={[{ required: true }]}>
                <Input placeholder="Enter the price" prefix="$" type="number" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    price: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Quantity (Size S)" name="quantityS" rules={[{ required: true }]}>
                <Input placeholder="Enter quantity for size S" type="number" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    quantityS: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Quantity (Size M)" name="quantityM" rules={[{ required: true }]}>
                <Input placeholder="Enter quantity for size M" type="number" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    quantityM: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Cost" name="cost" rules={[{ required: true }]}>
                <Input placeholder="Enter the cost" prefix="$" type="number" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    cost: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Margin" name="margin">
                <Input placeholder="Margin" prefix="%" value={baseProduct.margin} readOnly />
            </Form.Item>
            <Form.Item label="Product Images" name="images">
                <Upload
                    listType="picture-card"
                    fileList={baseProduct.images}
                    onPreview={file => {
                        const src = file.url;
                        const imgWindow = window.open(src, '_blank');
                        imgWindow.document.write(`<img src="${src}" style="width:100%;">`);
                    }}
                    onChange={handleChange}
                    beforeUpload={() => false}
                    multiple
                >
                    {baseProduct.images.length >= 8 ? null : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
            <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
                <Input placeholder="Enter the SKU" onChange={e => setBaseProduct(prevState => ({
                    ...prevState,
                    sku: e.target.value
                }))} />
            </Form.Item>
            {baseProduct.qrCode && (
                <Form.Item label="QR Code">
                    <QRCode value={baseProduct.qrCode} size={128} level="H" />
                </Form.Item>
            )}
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={uploading}>
                    Submit Product Data
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BaseProduct;

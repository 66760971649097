import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { message } from 'antd';  // Import message from Ant Design
import { firestore } from '../firebaseConfig';
import './CombinationDetails.css';

const CombinationDetails = () => {
  const { combinationId } = useParams();
  const navigate = useNavigate();  // To navigate to the product details page
  const [combinationDetails, setCombinationDetails] = useState(null);
  const imageRefs = useRef([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

  useEffect(() => {
    const fetchCombinationDetails = async () => {
      const docRef = doc(firestore, 'fullproducts', combinationId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCombinationDetails(docSnap.data());
      } else {
        console.error('No combination found!');
      }
    };

    fetchCombinationDetails();
  }, [combinationId]);

  const handleImageLoad = (index) => {
    setImageLoaded(true);
  };

  const getTagPositionOnDevice = (xPercent, yPercent, imageElement) => {
    const imageWidth = imageElement.offsetWidth;
    const imageHeight = imageElement.offsetHeight;
  
    const x = (xPercent / 100) * imageWidth;
    const y = (yPercent / 100) * imageHeight;
  
    return { x, y };
  };
  
  const handleTagPosition = (tag, imageIndex) => {
    const imageElement = imageRefs.current[imageIndex];
    if (imageElement) {
      const { x, y } = getTagPositionOnDevice(tag.x, tag.y, imageElement);
      return { x, y };
    }
    return { x: 0, y: 0 };
  };  

  const handleTagClick = (productId, productCollection) => {
    if (productId) {
      // Navigate to the specific product page based on product ID and collection
      navigate(`/${productCollection}/${productId}`);
    } else {
      message.error("Product ID is not available.");
    }
  };

  const renderTags = (tags, imageIndex) => {
    if (!imageLoaded) return null; // Ensure image is loaded before calculating positions

    return tags
      .filter(tag => tag.imageIndex === imageIndex) // Only show tags for this image
      .map((tag, index) => {
        const { x, y } = handleTagPosition(tag, imageIndex); // Calculate position
        const delay = `${index * 0.3}s`; // Delay each tag by 0.3s

        return (
          <div
            key={index}
            className={`combination-tag combination-tag-${tag.direction}`}
            style={{
              left: `${x}px`,
              top: `${y}px`,
              transform: 'translate(-50%, -50%)',
              animationDelay: delay, // Apply delay to each tag
            }}
            onClick={() => handleTagClick(tag.productId, 'baseProducts')}  // Link to product details page on click
          >
            <div className="combination-tag-arrow"></div>
            <div className="combination-tag-label">{tag.label}</div>
          </div>
        );
      });
  };

  if (!combinationDetails) {
    return <div>Loading combination details...</div>;
  }

  return (
    <div className="combination-details-container">
      <div className="combination-product-images-container">
        {combinationDetails.imageUrls?.map((url, index) => (
          <div key={index} className="combination-image-with-tags">
            <img
              src={url}
              alt={`${combinationDetails.name} view ${index}`}
              className="combination-product-image"
              ref={(el) => (imageRefs.current[index] = el)}
              onLoad={() => handleImageLoad(index)}
              loading="lazy" // Lazy loading for the image
            />
            <div className="combination-tags-container">
              {combinationDetails.tagsPerImage?.length > 0 &&
                renderTags(combinationDetails.tagsPerImage, index)}
            </div>
          </div>
        ))}
      </div>

      <div className="combination-product-info-container">
        <h1 className="combination-main-title">{combinationDetails.name}</h1>
        <p className="combination-product-price">${combinationDetails.setPrice} USD</p>
        <p className="combination-sub-title combination-product-description">
          Total Price: ${combinationDetails.totalPrice} USD
        </p>
      </div>
    </div>
  );
};

export default CombinationDetails;

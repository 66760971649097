import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext'; // Use the custom hook
import Navbar from '../Navbar';
import Footer from '../Footer/Footer';
import { message } from 'antd';
import './ProductDetails.css';

const JewelryDetails = () => {
    const { user } = useContext(useUser);
    const { productId } = useParams();
    const [jewelryDetails, setJewelryDetails] = useState(null);
    const navigate = useNavigate();

    // Fetch jewelry details
    useEffect(() => {
        const fetchJewelryDetails = async () => {
            const docRef = doc(firestore, "jewelry", productId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setJewelryDetails(docSnap.data());
            } else {
                message.error("No such jewelry item found!");
                navigate('/shop');
            }
        };

        fetchJewelryDetails();
    }, [productId, navigate]);

    const handleAddToCart = async () => {
        if (!user) {
            message.error("Please log in to add items to cart.");
            navigate('/login');  // Ensure redirection to the login page if not logged in
            return;
        }
    
        try {
            const cartRef = collection(firestore, `users/${user.uid}/cart`);
            const newCartItem = {
                productId: productId,
                name: jewelryDetails.name,
                description: jewelryDetails.description,
                price: jewelryDetails.price,
                imageUrls: jewelryDetails.imageUrls,
                quantity: 1  // Set default quantity to 1, adjust based on your app needs
            };
    
            // Check if the item already exists in the cart
            const cartQuery = query(cartRef, where("productId", "==", productId));
            const querySnapshot = await getDocs(cartQuery);
    
            if (querySnapshot.empty) {
                // Item not in cart, add new item
                await addDoc(cartRef, newCartItem);
                message.success("Item added to cart!");
            } else {
                // Item exists, update the quantity
                querySnapshot.forEach(async (docSnapshot) => {
                    const itemRef = doc(firestore, `users/${user.uid}/cart`, docSnapshot.id);
                    const updatedQuantity = docSnapshot.data().quantity + 1; // Increment quantity
                    await updateDoc(itemRef, { quantity: updatedQuantity });
                    message.success("Item quantity updated in cart!");
                });
            }
        } catch (error) {
            message.error("Failed to add item to cart.");
            console.error("Error adding item to cart: ", error);
        }
    };    

    if (!jewelryDetails) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Navbar />
            <div className="product-details-container">
                <div className="product-images-container">
                    {jewelryDetails.imageUrls?.map((url, index) => (
                        <img key={index} src={url} alt={`${jewelryDetails.name} view ${index}`} className="product-image" />
                    ))}
                </div>
                <div className="product-info-container">
                    <h1 className="main-title">{jewelryDetails.name}</h1>
                    <p className="product-description">{jewelryDetails.longDescription}</p>
                    <p className="product-price">${jewelryDetails.price?.toFixed(2)} USD</p>
                    <div className="pickup-info">
                        <div>Ready in one hour</div> 
                    </div>
                    <div className="pickup-info">
                        <div>Deliver in the same day</div> 
                    </div>
                    <div className="pickup-info">
                        <div>Free Delivery anywhere in Venezuela</div> 
                    </div>
                    <button onClick={handleAddToCart} className="buy-button">Add to Cart</button>
                </div>
            </div>
            <Footer />
        </>
    );    
};

export default JewelryDetails;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './TermsAndConditions.css'; // Reusing the same CSS file for consistent styling
import Navbar from './Navbar';
import Footer from './Footer/Footer';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | LE SMOKE U</title>
        <meta name="description" content="Read our privacy policy to understand how we collect, use, and protect your personal data at LE SMOKE U." />
      </Helmet>
      <Navbar />
      <div className="terms-container">
        <h2 className="terms-title">POLÍTICA DE PRIVACIDAD</h2>

        <section className="terms-section">
          <h3 className="terms-subtitle">1. Introducción</h3>
          <p className="terms-text">
            En LE SMOKE U, nos comprometemos a proteger tu privacidad. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos tu información personal cuando utilizas nuestro sitio web y servicios.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">2. Recopilación de Datos</h3>
          <p className="terms-text">
            Podemos recopilar la siguiente información personal:
          </p>
          <ul className="terms-list">
            <li>Nombre</li>
            <li>Dirección de correo electrónico</li>
            <li>Dirección postal</li>
            <li>Número de teléfono</li>
            <li>Información de pago</li>
            <li>Datos de ubicación</li>
          </ul>
          <p className="terms-text">
            También podemos recopilar información demográfica como edad, género y preferencias personales para mejorar nuestra estrategia de marketing.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">3. Uso de la Información</h3>
          <p className="terms-text">
            Usamos la información recopilada para los siguientes propósitos:
          </p>
          <ul className="terms-list">
            <li>Mejorar nuestros productos y servicios</li>
            <li>Personalizar tu experiencia en nuestro sitio web</li>
            <li>Procesar transacciones</li>
            <li>Enviar correos electrónicos periódicos</li>
            <li>Mejorar nuestras campañas de marketing utilizando datos de ubicación y CRM</li>
          </ul>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">4. Protección de la Información</h3>
          <p className="terms-text">
            Implementamos diversas medidas de seguridad para mantener la seguridad de tu información personal. Sin embargo, no podemos garantizar la seguridad absoluta de los datos transmitidos a través de Internet.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">5. Divulgación a Terceros</h3>
          <p className="terms-text">
            No vendemos, intercambiamos ni transferimos a terceros tu información personal sin tu consentimiento, excepto para el propósito expreso de entregar el producto o servicio adquirido. Podemos compartir información demográfica genérica no vinculada a ninguna información personal identificable con nuestros socios comerciales, afiliados de confianza y anunciantes.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">6. Derechos del Usuario</h3>
          <p className="terms-text">
            Tienes derecho a acceder, rectificar, borrar tus datos personales y otros derechos establecidos en las leyes aplicables. Para ejercer estos derechos, por favor contáctanos a lesmokeu@gmail.com.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">7. Retención de Datos</h3>
          <p className="terms-text">
            Retenemos tus datos personales solo durante el tiempo necesario para cumplir con los propósitos para los que fueron recopilados, incluyendo para satisfacer cualquier requisito legal, contable o de informes.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">8. Uso de Google Analytics</h3>
          <p className="terms-text">
            Utilizamos Google Analytics para recopilar datos anónimos sobre cómo los visitantes interactúan con nuestro sitio web. Esto nos ayuda a mejorar la experiencia del usuario y comprender mejor a nuestra audiencia. La información recopilada incluye datos sobre el dispositivo, navegador y ubicación geográfica del usuario. Al aceptar el uso de Google Analytics, consientes la recopilación y el procesamiento de estos datos. Puedes retirar tu consentimiento en cualquier momento.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">9. Información de Contacto</h3>
          <p className="terms-text">
            Si tienes alguna pregunta sobre esta Política de Privacidad, por favor contáctanos en lesmokeu@gmail.com.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, query, where, collection, doc, getDoc, updateDoc, setDoc, orderBy, arrayRemove, arrayUnion } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import { CartContext } from '../Cart/CartContext';
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Banner from './Banner';
import FilterOptions from './FilterOptions';
import ProductList from './ProductList';
import './Shop.css';

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const { updateCartItems } = useContext(CartContext);
  const [likedProducts, setLikedProducts] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    category: 'all',
    collectionType: 'all',
    sortOrder: 'name-asc',
  });
  const [totalProducts, setTotalProducts] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchFilteredProducts = async () => {
    setIsLoading(true);
    let productQueries = [];

    // Fetch baseProducts
    if (filterCriteria.collectionType === 'all' || filterCriteria.collectionType === 'baseProducts') {
      let baseProductsQuery = collection(firestore, 'baseProducts');
      if (filterCriteria.category !== 'all') {
        baseProductsQuery = query(baseProductsQuery, where('category', '==', filterCriteria.category));
      }
      baseProductsQuery = query(baseProductsQuery, orderBy('name', filterCriteria.sortOrder === 'name-asc' ? 'asc' : 'desc'));
      productQueries.push(getDocs(baseProductsQuery));
    }

    // Fetch jewelry products
    if (filterCriteria.collectionType === 'all' || filterCriteria.collectionType === 'jewelry') {
      let jewelryQuery = collection(firestore, 'jewelry');
      if (filterCriteria.category !== 'all') {
        jewelryQuery = query(jewelryQuery, where('category', '==', filterCriteria.category));
      }
      jewelryQuery = query(jewelryQuery, orderBy('name', filterCriteria.sortOrder === 'name-asc' ? 'asc' : 'desc'));
      productQueries.push(getDocs(jewelryQuery));
    }

    // Fetch fullproducts (combinations)
    if (filterCriteria.collectionType === 'all' || filterCriteria.collectionType === 'fullproducts') {
      let fullProductsQuery = collection(firestore, 'fullproducts');
      if (filterCriteria.category !== 'all') {
        fullProductsQuery = query(fullProductsQuery, where('category', '==', filterCriteria.category));
      }
      fullProductsQuery = query(fullProductsQuery, orderBy('name', filterCriteria.sortOrder === 'name-asc' ? 'asc' : 'desc'));
      productQueries.push(getDocs(fullProductsQuery));
    }

    const results = await Promise.all(productQueries);
    const fetchedProducts = results.flatMap(result => result.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      collection: result.query._query.path.segments[0], // Identify the collection
    })));

    setProducts(fetchedProducts);
    setTotalProducts(fetchedProducts.length);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, [filterCriteria]);

  const goToProductDetails = (collection, productId) => {
    if (collection === 'fullproducts') {
      navigate(`/combination/${productId}`); // Navigate to CombinationDetails if it's a combination product
    } else {
      navigate(`/${collection}/${productId}`); // Navigate to ProductDetails for other product types
    }
  };

  const handleAddToCart = async (product) => {
    if (!user) {
      toast.error('Please log in to add items to cart.');
      return;
    }

    const newItem = { ...product, quantity: 1 };
    const cartRef = doc(firestore, `carts/${user.uid}`);
    const cartDoc = await getDoc(cartRef);

    let newItems;
    if (!cartDoc.exists()) {
      newItems = [newItem];
      await setDoc(cartRef, { items: newItems });
    } else {
      newItems = [...cartDoc.data().items, newItem];
      await updateDoc(cartRef, { items: newItems });
    }

    updateCartItems(newItems);
    toast.success('Item added to cart!');
  };

  const handleLikeClick = async (e, product) => {
    e.stopPropagation();
    if (!user) {
      toast.error('Please log in to like products.');
      return;
    }

    const likeRef = doc(firestore, `likes/${user.uid}`);
    if (likedProducts.includes(product.id)) {
      await updateDoc(likeRef, { likedProducts: arrayRemove(product.id) });
      setLikedProducts(likedProducts.filter(id => id !== product.id));
      toast.success('Product unliked!');
    } else {
      await updateDoc(likeRef, { likedProducts: arrayUnion(product.id) });
      setLikedProducts([...likedProducts, product.id]);
      toast.success('Product liked!');
    }
  };

  return (
    <>
      <Helmet>
        <title>Shop | LE SMOKE U</title>
        <meta name="description" content="Explore our exclusive collection at LE SMOKE U. Premium quality fashion and jewelry." />
        <link rel="canonical" href="https://yourshopurl.com/shop" />
      </Helmet>
      <Navbar />
      <div className="shop-container">
        <Banner />
        <FilterOptions filterCriteria={filterCriteria} setFilterCriteria={setFilterCriteria} totalProducts={totalProducts} />
        <ProductList
          products={products}
          isLoading={isLoading}
          goToProductDetails={goToProductDetails}
          handleAddToCart={handleAddToCart}
          handleLikeClick={handleLikeClick}
          likedProducts={likedProducts}
        />
      </div>
    </>
  );
};

export default Shop;

import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css'; // Ensure appropriate styles are applied

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const UsersCRM = () => {
  const [municipioData, setMunicipioData] = useState({
    labels: [],
    datasets: [{
      label: 'Users per Municipio',
      data: [],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgb(75, 192, 192)',
    }]
  });
  const [usersOverTime, setUsersOverTime] = useState({
    labels: [],
    datasets: [{
      label: 'Daily User Registrations',
      data: [],
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(54, 162, 235)',
    }]
  });
  const [registrationTimes, setRegistrationTimes] = useState({
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
    datasets: [{
      label: 'Registrations per Hour',
      data: Array(24).fill(0),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: 'rgb(255, 99, 132)',
    }]
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "users"));
    const municipioCount = {};
    const dailyRegistrations = {};
    const hourlyRegistrations = Array(24).fill(0);

    querySnapshot.docs.forEach(doc => {
      const data = doc.data();
      const creationDate = data.creationTime.toDate();
      const dateKey = creationDate.toISOString().split('T')[0];
      const hour = creationDate.getHours();

      municipioCount[data.municipio] = (municipioCount[data.municipio] || 0) + 1;
      dailyRegistrations[dateKey] = (dailyRegistrations[dateKey] || 0) + 1;
      hourlyRegistrations[hour]++;
    });

    setMunicipioData({
      labels: Object.keys(municipioCount),
      datasets: [{ ...municipioData.datasets[0], data: Object.values(municipioCount) }]
    });

    setUsersOverTime({
      labels: Object.keys(dailyRegistrations).sort(),
      datasets: [{ ...usersOverTime.datasets[0], data: Object.values(dailyRegistrations) }]
    });

    setRegistrationTimes({
      labels: registrationTimes.labels,
      datasets: [{ ...registrationTimes.datasets[0], data: hourlyRegistrations }]
    });
  };

  return (
    <div className="dashboard-container">
      <h1>User Insights</h1>
      <div className="stats">
        <div className="graph-item">
          <h2>Users by Municipio</h2>
          <Bar data={municipioData} options={{ scales: { y: { beginAtZero: true } } }} />
        </div>
        <div className="graph-item">
          <h2>Daily User Registrations</h2>
          <Line data={usersOverTime} options={{ scales: { y: { beginAtZero: true } } }} />
        </div>
        <div className="graph-item">
          <h2>Registration Times Throughout the Day</h2>
          <Bar data={registrationTimes} options={{ scales: { y: { beginAtZero: true } } }} />
        </div>
      </div>
    </div>
  );
};

export default UsersCRM;
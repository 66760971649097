import React, { useState } from 'react';
import './ProductItem.css';

const ProductItem = ({ product, goToProductDetails }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="product-item" onClick={() => goToProductDetails(product.collection, product.id)}>
      <div className="product-images">
        {/* Skeleton is displayed until the primary image is fully loaded */}
        {!isImageLoaded && <div className="skeleton-image"></div>}

        {/* Primary image with skeleton loading */}
        <img
          src={product.imageUrls[0]}
          alt={`Main view of ${product.name}`}
          className={`primary-image ${isImageLoaded ? 'loaded' : 'hidden'}`}
          onLoad={handleImageLoad}
        />

        {/* Secondary image with lazy loading */}
        {product.imageUrls.length > 1 && (
          <img
            src={product.imageUrls[1]}
            alt={`Hover view of ${product.name}`}
            className={`secondary-image ${isImageLoaded ? 'loaded' : 'hidden'}`}
            loading="lazy" 
          />
        )}
      </div>
      <div className="product-info">
        <h3>{product.name}</h3>
        <p className="product-description-2">{product.shortDescription}</p>
      </div>
    </div>
  );
};

export default ProductItem;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat';
import moment from 'moment'; // Moment.js for date parsing and manipulation
import './CRM.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const googleApiKey = "AIzaSyBpEeoEGEP9PRuAxo8819Z94fwx2vL6qpU"; // Replace with your actual API key

const geocodeLocation = async (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}&language=en`;
    try {
        const response = await axios.get(url);
        if (response.data.status === 'OK') {
            return response.data.results[0].formatted_address;
        } else {
            return "Address not found";
        }
    } catch (error) {
        return "Address not found due to API error";
    }
};

const HeatmapLayer = ({ locations }) => {
    const map = useMap();
    useEffect(() => {
        const heatPoints = locations.map(loc => [loc.latitude, loc.longitude]);
        const heatLayer = L.heatLayer(heatPoints, { radius: 25 }).addTo(map);
        return () => map.removeLayer(heatLayer);
    }, [locations, map]);
    return null;
};

const CRM = () => {
    const [locations, setLocations] = useState([]);
    const [loginInsights, setLoginInsights] = useState({});
    const [showHeatmap, setShowHeatmap] = useState(true);

    useEffect(() => {
        const fetchLocations = async () => {
            const querySnapshot = await getDocs(collection(firestore, "aggregate_data"));
            const locationsData = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                const address = await geocodeLocation(data.latitude, data.longitude);
                return { ...data, address, timestamp: data.timestamp };
            }));
            setLocations(locationsData);
            analyzeLoginTimes(locationsData);
        };

        fetchLocations();
    }, []);

    const analyzeLoginTimes = (data) => {
        const timeCounts = data.reduce((acc, { timestamp }) => {
            const hour = moment(timestamp).format('HH'); // Extract hour from timestamp
            acc[hour] = (acc[hour] || 0) + 1;
            return acc;
        }, {});
        setLoginInsights(timeCounts);
    };

    const data = {
        labels: Object.keys(loginInsights).sort().map(hour => `${hour}:00`),
        datasets: [
            {
                label: 'Logins Per Hour',
                data: Object.keys(loginInsights).sort().map(hour => loginInsights[hour]),
                borderColor: '#292929', // Corresponds to --text-color-3
                backgroundColor: '#fff', // Transparent version of --text-color-3
                color: '#292929', // Corresponds to --secondary-color
                pointBackgroundColor: '#292929', // Same as border color for consistency
                pointBorderColor: '#fff', // White borders for points
                pointHoverBackgroundColor: '#fff', // Hover state for point background
                pointHoverBorderColor: '#292929' // Hover state for point borders
            },
        ],
    };    

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#292929' // Text color for Y-axis labels
                }
            },
            x: {
                ticks: {
                    color: '#292929' // Text color for X-axis labels
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                color: '#292929' // Legend text color
            }
        }
    };

    const toggleView = () => {
        setShowHeatmap(!showHeatmap);
    };

    return (
      <div className="crm-container">
          <div className="header-container">
              <h2 className="title">Location Data and Heatmap</h2>
              <button className="toggle-view-button" onClick={toggleView}>
                  {showHeatmap ? "Show Table" : "Show Heatmap"}
              </button>
          </div>
          {!showHeatmap && (
              <div>
                  <h3>Login Time Insights</h3>
                  <Line data={data} options={options} />
                  <table>
                      <thead>
                          <tr>
                              <th>Hour of Day</th>
                              <th>Logins</th>
                          </tr>
                      </thead>
                      <tbody>
                          {Object.entries(loginInsights).map(([hour, count]) => (
                              <tr key={hour}>
                                  <td>{hour}:00</td>
                                  <td>{count}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          )}
          <MapContainer center={[10.4806, -66.9036]} zoom={12} style={{ height: '800px', width: '100%', borderRadius: '8px' }}>
              <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {showHeatmap && <HeatmapLayer locations={locations} />}
              {!showHeatmap && locations.map((loc) => (
                  <Marker position={[loc.latitude, loc.longitude]} key={`${loc.latitude}-${loc.longitude}`}>
                      <Popup>{loc.address}<br/>Timestamp: {loc.timestamp}</Popup>
                  </Marker>
              ))}
          </MapContainer>
          {!showHeatmap && (
              <table style={{ width: '100%', marginTop: '20px' }}>
                  <thead>
                      <tr>
                          <th>Address</th>
                          <th>Timestamp</th>
                      </tr>
                  </thead>
                  <tbody>
                      {locations.map((loc, index) => (
                          <tr key={index}>
                              <td>{loc.address}</td>
                              <td>{loc.timestamp}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          )}
      </div>
    );  
};

export default CRM;
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiShoppingBag, FiX } from 'react-icons/fi'; // Feather icons for Cart and Close
import { CartContext } from './Cart/CartContext';
import { LanguageContext } from './LanguageContext'; // Import LanguageContext
import { useUser } from './UserContext'; // Use useUser from UserContext for user data
import NavbarMobile from './NavbarMobile'; // Import NavbarMobile for mobile screens
import './Navbar.css';

const Navbar = () => {
  const { cartCount } = useContext(CartContext); // Use CartContext to get the cart count
  const { language } = useContext(LanguageContext); // Use LanguageContext for language selection
  const { user } = useUser(); // Use useUser to get user details from UserContext
  const [isMobile, setIsMobile] = useState(false); // State to track screen size
  const [navActive, setNavActive] = useState(false);

  // Define translations for the Navbar
  const translations = {
    en: {
      shop: 'Shop',
      account: 'Account',
      cart: 'Cart',
      home: 'Home',
      orders: 'Orders',
      terms: 'Terms And Conditions',
      admin: 'Admin Panel',
      affiliate: 'Affiliate Panel',
    },
    es: {
      shop: 'Tienda',
      account: 'Cuenta',
      cart: 'Carrito',
      home: 'Inicio',
      orders: 'Pedidos',
      terms: 'Términos y Condiciones',
      admin: 'Panel de Administrador',
      affiliate: 'Panel de Afiliados',
    },
  };

  // Function to handle window resizing
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set to true if screen width is 768px or less
  };

  useEffect(() => {
    // Add resize event listener on mount
    handleResize(); // Check screen size when the component mounts
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  // Render mobile navbar if isMobile is true
  if (isMobile) {
    return <NavbarMobile />;
  }

  return (
    <>
      <nav className="navbar-main">
        <div className="navbar-left">
          <Link to="/shop" className="navbar-link navbar-shop-link">
            {translations[language].shop}
          </Link>
          <Link to={user ? "/account" : "/signup"} className="navbar-link navbar-account-link">
            {translations[language].account}
          </Link>
        </div>
        <Link to="/" className="navbar-brand-name">
          LE SMOKE U
        </Link>
        <div className="navbar-end">
          <Link to="/cart" className="navbar-link navbar-cart-link">
            <FiShoppingBag className="navbar-icon" /> {translations[language].cart} {cartCount > 0 && <span className="navbar-cart-count">({cartCount})</span>}
          </Link>
          <div className="menu-link-toggle" onClick={toggleNav}>
            <span className="menu-link-text">+ </span> {/* "+" symbol */}
            <span className="menu-more-text">More</span> {/* "More" text next to the "+" */}
          </div>
        </div>
      </nav>

      {/* Menu */}
      <div className={`menu-links-container ${navActive ? 'menu-active' : ''}`}>
        <Link to="/" onClick={() => setNavActive(false)} className="menu-link-item">
          {translations[language].home}
        </Link>
        <Link to="/shop" onClick={() => setNavActive(false)} className="menu-link-item">
          {translations[language].shop}
        </Link>
        <Link to="/account" onClick={() => setNavActive(false)} className="menu-link-item">
          {translations[language].account}
        </Link>
        <Link to="/orders" onClick={() => setNavActive(false)} className="menu-link-item">
          {translations[language].orders}
        </Link>
        <Link to="/termsandconditions" onClick={() => setNavActive(false)} className="menu-link-item">
          {translations[language].terms}
        </Link>

        {/* Show Admin Panel if user is admin */}
        {user?.role === 'admin' && (
          <Link to="/admin-panel" onClick={() => setNavActive(false)} className="menu-link-item">
            {translations[language].admin}
          </Link>
        )}

        {/* Show Affiliate Panel if user is affiliate or admin */}
        {(user?.role === 'affiliate' || user?.role === 'admin') && (
          <Link to="/affiliate" onClick={() => setNavActive(false)} className="menu-link-item">
            {translations[language].affiliate}
          </Link>
        )}
      </div>
    </>
  );
};

export default Navbar;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDr00aJULhPnid1XEj868hRGiv3KH7W7uY",
  authDomain: "clothing-cb1a9.firebaseapp.com",
  projectId: "clothing-cb1a9",
  storageBucket: "clothing-cb1a9.appspot.com",
  messagingSenderId: "77501281380",
  appId: "1:77501281380:web:3aeaf9f963193b77089b71",
  measurementId: "G-N68YHKLXGX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
const analytics = getAnalytics(app);

// Export app, auth, and firestore
export { app, auth, firestore, storage };
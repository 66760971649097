import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext'; // Use the custom hook
import Navbar from '../Navbar';
import Footer from '../Footer/Footer';
import { message, Modal } from 'antd';
import './ProductDetails.css';
import { Helmet } from 'react-helmet-async';
import Spinner from '../Spinner'; // Ensure the path is correct

const ProductDetails = () => {
    const { user } = useUser(); // Use custom hook for user data
    const { productId } = useParams();
    const [productDetails, setProductDetails] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null); // State for selected size
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    useEffect(() => {
        const fetchProductDetails = async () => {
            const docRef = doc(firestore, "baseProducts", productId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProductDetails(docSnap.data());
            } else {
                message.error("No such product found!");
                navigate('/shop');
            }
        };

        fetchProductDetails();
    }, [productId, navigate]);

    const handleAddToCart = async () => {
        if (!user) {
            message.error("Please log in to add items to cart.");
            navigate('/signup'); // Redirect to login if not authenticated
            return;
        }
    
        if (!selectedSize) {
            message.error("Please select a size.");
            return;
        }

        const cartCollectionRef = collection(firestore, `users/${user.uid}/cart`);
        const newCartItem = {
            productId: productId,
            name: productDetails.name,
            description: productDetails.shortDescription,
            price: productDetails.price,
            imageUrls: productDetails.imageUrls,
            quantity: 1, // Default quantity set to 1
            size: selectedSize // Add the selected size to the cart item
        };

        try {
            // Check if item already exists in the user's cart
            const cartQuery = query(cartCollectionRef, where("productId", "==", productId), where("size", "==", selectedSize));
            const querySnapshot = await getDocs(cartQuery);
            
            if (querySnapshot.empty) {
                // If the item doesn't exist in the cart, add it
                await addDoc(cartCollectionRef, newCartItem);
                message.success("Item added to cart!");
            } else {
                // If the item exists, update its quantity
                querySnapshot.forEach(async (cartDoc) => {
                    const itemRef = doc(firestore, `users/${user.uid}/cart`, cartDoc.id);
                    const updatedQuantity = cartDoc.data().quantity + 1; // Increment quantity
                    await updateDoc(itemRef, { quantity: updatedQuantity });
                    message.success("Item quantity updated in cart!");
                });
            }
        } catch (error) {
            message.error("Failed to add item to cart.");
            console.error("Error adding item to cart: ", error);
        }
    };

    if (!productDetails) {
        return <Spinner />;
    }

    return (
        <>
            <Helmet>
                <title>{productDetails ? `${productDetails.name} | LE SMOKE U` : 'Product Details | LE SMOKE U'}</title>
                <meta name="description" content={productDetails ? `Buy ${productDetails.name} at LE SMOKE U. ${productDetails.longDescription}` : 'View product details at LE SMOKE U.'} />
            </Helmet>
            <Navbar />
            <div className="product-details-container">
                <div className="product-images-container">
                    {productDetails.imageUrls?.map((url, index) => (
                        <img key={index} src={url} alt={`${productDetails.name} view ${index}`} className="product-image" />
                    ))}
                </div>
                <div className="product-info-container">
                    <h1 className="main-title">{productDetails.name}</h1>
                    <p className="product-price">${productDetails.price?.toFixed(2)} USD</p>
                    <p className="sub-title product-description">{productDetails.longDescription}</p> 
                    <div className="product-selection">
                        <div className="size-selection">
                            <div className="size-stock-container">
                                <button
                                    className={`size-option ${productDetails.quantityS === 0 ? 'sold-out' : ''} ${selectedSize === 'S' ? 'selected' : ''}`}
                                    disabled={productDetails.quantityS === 0}
                                    onClick={() => setSelectedSize('S')}
                                >
                                    S
                                </button>
                                {productDetails.quantityS === 0 && <span className="stock-info">Sold Out</span>}
                            </div>
                            <div className="size-stock-container">
                                <button
                                    className={`size-option ${productDetails.quantityM === 0 ? 'sold-out' : ''} ${selectedSize === 'M' ? 'selected' : ''}`}
                                    disabled={productDetails.quantityM === 0}
                                    onClick={() => setSelectedSize('M')}
                                >
                                    M
                                </button>
                                {productDetails.quantityM === 0 && <span className="stock-info">Sold Out</span>}
                            </div>
                        </div>
                    </div>
                    <div className="pickup-info">Ready in one hour</div>
                    <div className="pickup-info">Deliver in the same day</div>
                    <div className="pickup-info">Free Delivery anywhere in Venezuela</div>
                    <button onClick={handleAddToCart} className="buy-button">Add to Cart</button>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;

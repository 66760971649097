import React from 'react';
import './Spinner.css';
import logo from './Logo2.png'; // Ensure the path is correct

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <img src={logo} alt="Loading..." className="spinner-logo" />
      </div>
    </div>
  );
};

export default Spinner;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Upload, message, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getDocs, collection, setDoc, doc } from 'firebase/firestore';
import { firestore, storage } from './firebaseConfig';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import './ProductAdding.css';

const { Option } = Select;

const ProductAdding = () => {
  const [form] = Form.useForm();
  const [baseProducts, setBaseProducts] = useState([]);
  const [combination, setCombination] = useState({
    name: '',
    product1: null,
    product2: null,
    totalPrice: 0,
    setPrice: 0,
    images: [],
    tagsPerImage: [], // Holds tags for each image
  });
  const [uploading, setUploading] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const imageRefs = useRef([]);
  const [activeTag, setActiveTag] = useState(null); // Track active tag for moving

  useEffect(() => {
    async function fetchData() {
      const baseData = await getDocs(collection(firestore, 'baseProducts'));
      setBaseProducts(baseData.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
    fetchData();
  }, []);

  const getTagPositionRelativeToWindow = (x, y) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return {
    xPercent: (x / width) * 100,
    yPercent: (y / height) * 100,
  };
};

const handleTagMouseDown = (tagId, imageIndex, event) => {
  event.preventDefault();
  setActiveTag({ tagId, imageIndex });
};

const handleMouseMove = (event) => {
  if (!activeTag) return;

  const { tagId, imageIndex } = activeTag;
  const { xPercent, yPercent } = getTagPositionRelativeToWindow(event.clientX, event.clientY);

  const updatedTags = combination.tagsPerImage.map((tags, index) => {
    if (index === imageIndex) {
      return tags.map((tag) =>
        tag.id === tagId
          ? { ...tag, x: Math.min(Math.max(xPercent, 0), 100), y: Math.min(Math.max(yPercent, 0), 100) }
          : tag
      );
    }
    return tags;
  });

  setCombination({ ...combination, tagsPerImage: updatedTags });
};

  const handleProductSelection = (productId, productKey) => {
    const selectedProduct = baseProducts.find((product) => product.id === productId);
    const updatedCombination = { ...combination };
    updatedCombination[productKey] = selectedProduct;
    updatedCombination.totalPrice =
      (updatedCombination.product1?.price || 0) +
      (updatedCombination.product2?.price || 0);
    setCombination(updatedCombination);
  };

  const handleCombinationImageChange = async (info) => {
    const newImages = await Promise.all(
      info.fileList.map(async (file) => {
        const compressedFile = await compressImage(file.originFileObj);
        return {
          uid: file.uid,
          name: compressedFile.name,
          status: file.status,
          originFileObj: compressedFile,
          url: file.url || (compressedFile ? URL.createObjectURL(compressedFile) : null),
        };
      })
    );
    const updatedCombination = { ...combination };
    updatedCombination.images = newImages;
    updatedCombination.tagsPerImage = newImages.map(() => []); // Initialize empty tags for each image
    setCombination(updatedCombination);
  };

  const compressImage = async (imageFile) => {
    const options = {
        maxSizeMB: 2, // Increase to 2 MB for better quality
        maxWidthOrHeight: 1920, // Larger dimensions for higher resolution
        useWebWorker: true, // Use web workers for better performance
        maxIteration: 10, // More iterations to improve quality
        initialQuality: 0.9 // Start compression at 90% quality
    };
    try {
        const compressedFile = await imageCompression(imageFile, options);
        return compressedFile;
    } catch (error) {
        console.error('Error compressing image:', error);
        return imageFile; // Return the original file if compression fails
    }
};

  const openImageForTagging = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
    setIsModalVisible(true);
  
    // Ensure there are two tags: one for product1 and one for product2
    if (!combination.tagsPerImage[imageIndex]?.length) {
      const product1Tag = {
        id: `${Date.now()}-1`,
        productId: combination.product1?.id || null, // Add product1's ID
        label: combination.product1?.name || 'Product 1',
        x: 50, // Center horizontally (percentage of image width)
        y: 40, // Near the center vertically
        direction: 'right',
      };
      const product2Tag = {
        id: `${Date.now()}-2`,
        productId: combination.product2?.id || null, // Add product2's ID
        label: combination.product2?.name || 'Product 2',
        x: 50, // Center horizontally (percentage of image width)
        y: 60, // Below the center vertically
        direction: 'right',
      };
  
      const updatedTags = [...combination.tagsPerImage];
      updatedTags[imageIndex] = [product1Tag, product2Tag]; // Add both tags for the image
      setCombination({ ...combination, tagsPerImage: updatedTags });
    }
  };  

  const handleMouseUp = () => {
    setActiveTag(null);
  };

  const saveTagPositions = () => {
    console.log('Saving tag positions:', combination.tagsPerImage[selectedImageIndex]);
    closeModalAndSave();
  };

  const closeModalAndSave = () => {
    setIsModalVisible(false);
    setSelectedImageIndex(null);
  };

  const addTag = (imageIndex) => {
    const newTag = {
      id: `${Date.now()}`,
      label: combination.product1?.name || 'Product 1',
      x: 50,
      y: 50,
      direction: 'right',
    };
    const updatedTags = [...combination.tagsPerImage];
    updatedTags[imageIndex] = [...updatedTags[imageIndex], newTag];
    setCombination({ ...combination, tagsPerImage: updatedTags });
  };

  const handleSubmit = async () => {
    setUploading(true);
    try {
      if (!combination.name || !combination.images.length || !combination.product1 || !combination.product2) {
        throw new Error('The combination must have a name, two selected products, and at least one image.');
      }

      const imageUrls = await Promise.all(
        combination.images.map(async (file) => {
          if (!file.originFileObj && file.url) return file.url;
          const imageRef = storageRef(storage, `combinations/${file.name}-${Date.now()}`);
          const snapshot = await uploadBytes(imageRef, file.originFileObj);
          return getDownloadURL(snapshot.ref);
        })
      );

      const sanitizedCombination = {
        name: combination.name || '',
        product1: combination.product1?.id || null,
        product2: combination.product2?.id || null,
        totalPrice: combination.totalPrice || 0,
        setPrice: combination.setPrice || 0,
        imageUrls,
        tagsPerImage: combination.tagsPerImage.flatMap((tags, imageIndex) =>
          tags.map((tag) => ({ imageIndex, ...tag }))
        ),
      };

      const productDocRef = doc(collection(firestore, 'fullproducts'));
      await setDoc(productDocRef, sanitizedCombination);

      message.success('Product and combination added successfully!');
      form.resetFields();
      setCombination({
        name: '',
        product1: null,
        product2: null,
        totalPrice: 0,
        setPrice: 0,
        images: [],
        tagsPerImage: [],
      });
    } catch (error) {
      message.error(`Error: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="product-adding-form-container">
        <Form.Item label="Combination Name" className="product-adding-form-item">
          <Input
            placeholder="Enter combination name"
            value={combination.name}
            onChange={(e) => setCombination({ ...combination, name: e.target.value })}
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
          <Form.Item label="Select First Product" className="product-adding-form-item">
  <Select
    placeholder="Select product 1"
    onChange={(value) => handleProductSelection(value, 'product1')}
    value={combination.product1?.id}
  >
    {baseProducts.map((product) => (
      <Option key={product.id} value={product.id}>
        <img 
          src={product.imageUrls?.[0] || 'fallback-image-url'} 
          alt={product.name} 
          style={{ width: '30px', height: '30px', marginRight: '10px', objectFit: 'cover' }}
        />
        {product.name} - ${product.price}
      </Option>
    ))}
  </Select>
</Form.Item>

<Form.Item label="Select Second Product" className="product-adding-form-item">
  <Select
    placeholder="Select product 2"
    onChange={(value) => handleProductSelection(value, 'product2')}
    value={combination.product2?.id}
  >
    {baseProducts.map((product) => (
      <Option key={product.id} value={product.id}>
        <img 
          src={product.imageUrls?.[0] || 'fallback-image-url'} 
          alt={product.name} 
          style={{ width: '30px', height: '30px', marginRight: '10px', objectFit: 'cover' }}
        />
        {product.name} - ${product.price}
      </Option>
    ))}
  </Select>
</Form.Item>
          </Col>
        </Row>

        <Form.Item label="Combination Images" className="product-adding-form-item">
          <Upload
            listType="picture-card"
            fileList={combination.images}
            onChange={handleCombinationImageChange}
            beforeUpload={() => false}
            multiple
          >
            {combination.images.length < 8 && (
              <div className="product-adding-upload-btn">
                <PlusOutlined />
                <div>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        {combination.images.map((image, imageIndex) => (
          <div key={image.uid} className="product-adding-image-tagging-container">
            <img
              ref={(el) => (imageRefs.current[imageIndex] = el)}
              src={image.url}
              alt={`Combination ${imageIndex}`}
              className="product-adding-taggable-image"
              onClick={() => openImageForTagging(imageIndex)}
            />
          </div>
        ))}

        <button type="submit" className="product-adding-submit-btn" disabled={uploading}>
          Submit Product Data
        </button>

        {isModalVisible && (
          <div className="product-adding-modal">
            <div className="product-adding-modal-overlay" onClick={closeModalAndSave} />
            <div className="product-adding-modal-content">
              {selectedImageIndex !== null && (
                <div className="product-adding-modal-image-container">
                  <img
                    ref={(el) => (imageRefs.current[selectedImageIndex] = el)}
                    src={combination.images[selectedImageIndex]?.url}
                    alt={`Selected Combination ${selectedImageIndex}`}
                    className="product-adding-modal-taggable-image"
                  />
                  {combination.tagsPerImage[selectedImageIndex]?.map((tag) => (
                    <div
                      key={tag.id}
                      className={`product-adding-tag product-adding-tag-${tag.direction}`}
                      style={{
                        top: `${tag.y}%`,
                        left: `${tag.x}%`,
                        transform: 'translate(-50%, -50%)',
                      }}
                      onMouseDown={(event) => handleTagMouseDown(tag.id, selectedImageIndex, event)}
                    >
                      <div className="product-adding-tag-arrow"></div>
                      <div className="product-adding-tag-label">{tag.label}</div>
                    </div>
                  ))}
                  <button className="product-adding-add-tag-btn" onClick={() => addTag(selectedImageIndex)}>
                    Add Tag
                  </button>
                </div>
              )}
              <button className="product-adding-save-btn" onClick={saveTagPositions}>
                Save
              </button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ProductAdding;

import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Modal, Button, Form, Input } from 'antd';
import './IdealCustomer.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const IdealCustomer = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [majorityValues, setMajorityValues] = useState({ municipio: '', age: '', gender: '' });
  const [viewMode, setViewMode] = useState('table');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();
  const [filter, setFilter] = useState('municipio');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'users'));
      const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
      setFilteredUsers(usersData);
      calculateMajority(usersData);
    } catch (error) {
      toast.error('Failed to fetch user data');
    }
  };

  const calculateMajority = (users) => {
    if (users.length === 0) return;

    const municipioCount = {};
    const ageCount = {};
    const genderCount = {};

    users.forEach(user => {
      municipioCount[user.municipio] = (municipioCount[user.municipio] || 0) + 1;
      ageCount[user.age] = (ageCount[user.age] || 0) + 1;
      genderCount[user.gender] = (genderCount[user.gender] || 0) + 1;
    });

    setMajorityValues({
      municipio: Object.keys(municipioCount).reduce((a, b) => municipioCount[a] > municipioCount[b] ? a : b),
      age: Object.keys(ageCount).reduce((a, b) => ageCount[a] > ageCount[b] ? a : b),
      gender: Object.keys(genderCount).reduce((a, b) => genderCount[a] > genderCount[b] ? a : b),
    });
  };

  const filterUsers = (filter) => {
    setFilter(filter);
    const filtered = users.filter(user => user[filter]);
    setFilteredUsers(filtered);
    calculateMajority(filtered);
  };

  const showModal = (user) => {
    setSelectedUser(user);
    form.setFieldsValue(user);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const userRef = doc(firestore, "users", selectedUser.id);
    await updateDoc(userRef, values);
    toast.success('User details updated successfully!');
    fetchUsers();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderChart = () => {
    const data = {
      labels: filteredUsers.map(user => user.firstName + ' ' + user.lastName),
      datasets: [
        {
          label: 'Users',
          data: filteredUsers.map(user => user[filter]),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        }
      ]
    };

    return <Line data={data} options={{ scales: { y: { beginAtZero: true } } }} />;
  };

  return (
    <div className="ideal-customer-container">
      <h2>Ideal Customer Profile</h2>
      <div className="view-mode-container">
        <label>View Mode:</label>
        <select onChange={(e) => setViewMode(e.target.value)} value={viewMode}>
          <option value="table">Table</option>
          <option value="chart">Chart</option>
        </select>
        <label>Filter By:</label>
        <select onChange={(e) => filterUsers(e.target.value)} value={filter}>
          <option value="municipio">Municipio</option>
          <option value="age">Age</option>
          <option value="gender">Gender</option>
        </select>
      </div>
      {viewMode === 'table' ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Municipio</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={index}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.municipio}</td>
                  <td>{user.age}</td>
                  <td>{user.gender}</td>
                  <td><Button onClick={() => showModal(user)}>Edit</Button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="chart-container">
          {renderChart()}
        </div>
      )}
      <div className="majority-values">
        <h3>Majority Values</h3>
        <p>Municipio: {majorityValues.municipio}</p>
        <p>Age: {majorityValues.age}</p>
        <p>Gender: {majorityValues.gender}</p>
      </div>
      {selectedUser && (
        <Modal
          title="Edit User Details"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>Return</Button>,
            <Button key="submit" type="primary" onClick={handleOk}>Update</Button>
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="firstName" label="First Name">
              <Input />
            </Form.Item>
            <Form.Item name="lastName" label="Last Name">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Phone Number">
              <Input />
            </Form.Item>
            <Form.Item name="municipio" label="Municipio">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
};

export default IdealCustomer;

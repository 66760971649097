import React, { useState } from 'react';
import { Upload, Button, message, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage';
import { storage, firestore } from './firebaseConfig';
import { doc, setDoc, collection } from 'firebase/firestore';
import './ImageUploader.css';

const { Option } = Select;

const ImageUploader = ({ onImagesUploaded }) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [section, setSection] = useState('homepageImages'); // Default to homepageImages

  const handleSectionChange = (value) => {
    setSection(value);
  };

  const handleUpload = async () => {
    if (!section) {
      message.error('Please select a section to upload the image to.');
      return;
    }

    setUploading(true);
    try {
      const uploadedUrls = await Promise.all(
        fileList.map(async (file) => {
          const compressedFile = await compressImage(file.originFileObj);
          const imageRef = storageRef(storage, `${section}/${file.name}-${Date.now()}`);
          const snapshot = await uploadBytes(imageRef, compressedFile);
          const downloadURL = await getDownloadURL(snapshot.ref);
          return downloadURL;
        })
      );

      // Save image URLs to Firestore under the selected section
      const imageDocRef = doc(collection(firestore, section));
      await setDoc(imageDocRef, { urls: uploadedUrls });

      message.success('Images uploaded successfully!');
      setFileList([]);
      onImagesUploaded(uploadedUrls);

    } catch (error) {
      message.error('Failed to upload images. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 3, // Allow up to 3MB for better quality
      maxWidthOrHeight: 2048, // Keep resolution to 2048px
      useWebWorker: true,
      initialQuality: 0.95,
      fileType: 'image/webp', // Convert to WebP
    };
    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      console.error('Image compression error:', error);
      return imageFile;
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <div className="image-uploader">
      <Select
        style={{ width: 300, marginBottom: '1rem' }}
        placeholder="Select a section"
        onChange={handleSectionChange}
        defaultValue={section}
      >
        <Option value="homepageImages">Homepage Images</Option>
        <Option value="styleImages">Style Section Images</Option>
        <Option value="testimonialsImages">Testimonials Section Images</Option>
        <Option value="testimonialsImage1">Testimonial Image 1</Option>
        <Option value="testimonialsImage2">Testimonial Image 2</Option>
        <Option value="bannerImages">Banner Image</Option> {/* Add this option */}
      </Select>

      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        beforeUpload={() => false}
        multiple
      >
        {fileList.length < 8 && <PlusOutlined />}
      </Upload>

      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || uploading}
        loading={uploading}
        className="upload-btn"
      >
        {uploading ? 'Uploading...' : 'Start Upload'}
      </Button>
    </div>
  );
};

export default ImageUploader;

import React from 'react';
import Spinner from './Spinner'; // Ensure the path is correct

const Testing = () => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

export default Testing;

import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai'; // Importing the search icon
import './Filter.css';

const FilterOptions = ({ filterCriteria, setFilterCriteria, totalProducts }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleFilterChange = (category, collectionType, sortOrder) => {
    setFilterCriteria({ category, collectionType, sortOrder });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    console.log('Searching for:', searchTerm);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="filter-container">
      <div className="search-filter-wrapper">
        {/* Search Bar */}
        <div className="search-bar-wrapper">
          {/* Icon inside the search bar */}
          <AiOutlineSearch className="search-icon" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
            placeholder="Search"
          />
          <button className="search-button" onClick={handleSearchClick}>
            Search
          </button>
          <button className="filter-button" onClick={toggleDropdown}>
            Filter
          </button>
        </div>

        {/* Total Products Info */}
        <div className="total-products">
          <span>{totalProducts} products</span>
        </div>

        {/* Dropdown for Filter Options */}
        {isDropdownVisible && (
          <div className="filter-options-dropdown">
            <span>Category:</span>
            <select
              value={filterCriteria.category}
              onChange={(e) =>
                handleFilterChange(e.target.value, filterCriteria.collectionType, filterCriteria.sortOrder)
              }
              className="filter-select"
            >
              <option value="all">All</option>
              <option value="blazer">Blazer</option>
              <option value="top">Top</option>
              <option value="pantalon">Pantalon</option>
              <option value="falda">Falda</option>
              <option value="dress">Vestido</option>
              <option value="others">Others</option>
            </select>

            <span>Types:</span>
            <select
              value={filterCriteria.collectionType}
              onChange={(e) =>
                handleFilterChange(filterCriteria.category, e.target.value, filterCriteria.sortOrder)
              }
              className="filter-select"
            >
              <option value="all">All Types</option>
              <option value="baseProducts">Clothing</option>
              <option value="jewelry">Cadenas</option>
              <option value="fullproducts">Combinations</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterOptions;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './AdminPanel.css';  // Reusing the same CSS for consistent styling
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import logo from './Logo.png';

// Importing affiliate-specific components
import GenerateLink from './GenerateLink';
import MarketingMaterials from './MarketingMaterials';
import AffiliateDashboard from './AffiliateDashboard';

const AffiliatePanel = () => {
  const { user } = useUser();  // Use custom hook for user data
  const [activeComponent, setActiveComponent] = useState('affiliateDashboard');
  const [dropdownVisible, setDropdownVisible] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setActiveComponent('affiliateDashboard');
  }, [user?.role]);

  const toggleDropdown = (name) => {
    setDropdownVisible(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('Logged out successfully');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'affiliateDashboard': return <AffiliateDashboard />;
      case 'generateLink': return <GenerateLink />;
      case 'marketingMaterials': return <MarketingMaterials />;
      default: return <AffiliateDashboard />;
    }
  };

  return (
    <div className="admin-panel">
      <div className="sidebar">
        <div className="brand-section-2">
          <img src={logo} alt="Logo" className="brand-logo-2" />
          <h1 className="brand-name-2">Le Smoke U</h1>
        </div>
        {(user?.role === 'affiliate' || user?.role === 'admin') && (
          <>
            <div className="affiliate-nav-links">
              <div className="affiliate-nav-link" onClick={() => setActiveComponent('affiliateDashboard')}>
                Dashboard
              </div>
              <div className="affiliate-nav-link" onClick={() => setActiveComponent('generateLink')}>
                Generate Link
              </div>
              <div className="affiliate-nav-link" onClick={() => setActiveComponent('marketingMaterials')}>
                Marketing Materials
              </div>
            </div>
          </>
        )}
        <div className="affiliate-nav-link" onClick={() => toggleDropdown('linksDropdown')}>
          LE SMOKE U - Links
          {dropdownVisible['linksDropdown'] && (
            <div className="dropdown-content">
              <div onClick={() => handleNavigation('/')}>Home</div>
              <div onClick={() => handleNavigation('/shop')}>Shop</div>
              <div onClick={() => handleNavigation('/cart')}>Cart</div>
              <div onClick={() => handleNavigation('/auth')}>Auth</div>
            </div>
          )}
        </div>
      </div>
      <div className="user-badge">
        <div className="user-info">
          <div className="user-details">
            <div>{user?.email}</div>
            <div>{user?.city}</div>
            <div className="status">{user?.status}</div>
          </div>
        </div>
        <div className="user-circle">{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}</div>
        <FontAwesomeIcon icon={faSignOutAlt} onClick={handleLogout} className="logout-icon" />
      </div>
      <div className="admin-content">
        {renderActiveComponent()}
      </div>
    </div>
  );
};

export default AffiliatePanel;

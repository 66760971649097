import React, { useState } from 'react';
import { FiShoppingBag, FiUser, FiCalendar, FiHeadphones } from 'react-icons/fi'; // Feather icons
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, writeBatch } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import Cookies from 'js-cookie'; // Import Cookies
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SignUp.css';
import signupImage from './Signup.jpeg'; // Your signup image here

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [showCompleteInfo, setShowCompleteInfo] = useState(false); // State for complete info step
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = emailRegex.test(email);
  const isValidPassword = password.length >= 6;

  const getBrowserLanguage = () => {
    return {
      language: navigator.language, // Only capture the browser's language
    };
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    
    if (!isValidEmail) {
      toast.error('Invalid email format.');
      console.log('Invalid email format:', email);
      return;
    }
  
    if (!isValidPassword) {
      toast.error('Password must be at least 6 characters.');
      console.log('Invalid password length:', password.length);
      return;
    }
  
    try {
      setLoading(true);
  
      // Step 1: Create user in Firebase Auth
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
  
      if (!user) {
        throw new Error('User authentication failed. Please try again.');
      }
  
      // Step 2: Confirm authentication state
      await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          console.log('Timeout: Authentication state not settled in time.');
          reject(new Error('Timeout waiting for authentication state to settle.'));
        }, 5000); // 5-second timeout
  
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
          if (authUser && authUser.uid === user.uid) {
            console.log('Authentication state confirmed for UID:', authUser.uid);
            clearTimeout(timeout); // Clear timeout if authentication is confirmed
            unsubscribe(); // Stop listening once the state is confirmed
            resolve();
          } else {
            console.log('Waiting for authentication state to settle...');
          }
        }, (error) => {
          console.error('Error in onAuthStateChanged:', error);
          clearTimeout(timeout);
          reject(error);
        });
      });
  
      toast.success('Sign-up successful! Please complete your profile.');
      setShowCompleteInfo(true); // Proceed to the complete profile step
    } catch (error) {
      console.error('Signup failed:', error);
      toast.error(`Signup failed: ${error.message}`);
    } finally {
      setLoading(false);
      console.log('Loading state set to false');
    }
  };  

  const handleGoogleSignIn = () => {
    setLoading(true);
    signInWithRedirect(auth, googleProvider)
      .catch((error) => {
        toast.error(`Google sign-in failed: ${error.message}`);
      })
      .finally(() => setLoading(false));
  };

  const handleCompleteInfoSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error('No authenticated user found.');
      }
  
      // User data to be saved in Firestore
      const userData = {
        email: user.email,
        firstName,
        lastName,
        phoneNumber,
        address,
        gender,
        age,
        createdAt: new Date(),
        ownerId: user.uid // Set the ownerId to the authenticated user's UID
      };
  
      // Set the document in Firestore
      await setDoc(doc(firestore, 'users', user.uid), userData);
      console.log('User profile successfully created in Firestore.');
  
      toast.success('Profile completed! Redirecting to the shop...');
      navigate('/shop');
    } catch (error) {
      console.error('Failed to save profile:', error);
      toast.error(`Failed to save profile: ${error.message}`);
    }
  };  

  const skipCompleteInfo = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found.');
      }
  
      // User data to be saved in Firestore
      const userData = {
        email: user.email,
        firstName,
        lastName,
        phoneNumber: phoneNumber || '', // Set empty if not provided
        address: address || '',         // Set empty if not provided
        gender: gender || '',           // Set empty if not provided
        age: age || '',                 // Set empty if not provided
        createdAt: new Date(),
        ownerId: user.uid // Set the ownerId to the authenticated user's UID
      };
  
      // Save the document in Firestore
      await setDoc(doc(firestore, 'users', user.uid), userData, { merge: true });
      console.log('User profile successfully saved in Firestore with skipped info.');
  
      toast.success('Profile saved! Redirecting to the shop...');
      navigate('/shop');
    } catch (error) {
      console.error('Failed to save profile:', error);
      toast.error(`Failed to save profile: ${error.message}`);
    }
  };  

  return (
    <div className="signup-wrapper">
      <div className="signup-container">
        <div className="signup-image-container">
          <img src={signupImage} alt="Signup Visual" className="signup-image" />
          <div className="signup-text-overlay">
            <h1>Create your style</h1>
          </div>
        </div>

        <div className="signup-form-container">
          {!showCompleteInfo ? (
            <form onSubmit={handleSignup} className="signup-form">
              <h2 className="signup-title">Sign Up</h2>

              <div className="name-fields">
                <input
                  type="text"
                  className="signup-input half-width"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  className="signup-input half-width"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <div className="signup-email-password-fields">
                <input
                  type="email"
                  className="signup-input half-width"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  className="signup-input half-width"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="signup-buttons">
                <button type="submit" className="signup-button" disabled={loading}>
                  {loading ? 'Signing Up...' : 'Sign Up'}
                </button>
                <button
                  type="button"
                  onClick={handleGoogleSignIn}
                  className="signup-button google"
                  disabled={loading}
                >
                  {loading ? 'Signing In...' : 'Sign in with Google'}
                </button>
              </div>

              <p onClick={() => navigate('/login')} className="toggle-auth">
                Already have an account? Log in
              </p>

              <div className="signup-benefits">
                <div className="benefits-column">
                  <h3>Shopping Benefits</h3>
                  <div className="benefit-item">
                    <FiShoppingBag className="benefit-icon" />
                    <p>Check the details and monitor the status of your orders and returns</p>
                  </div>
                  <div className="benefit-item">
                    <FiUser className="benefit-icon" />
                    <p>Create a wishlist to save your favorite items</p>
                  </div>
                </div>

                <div className="benefits-column">
                  <h3>Customer Services</h3>
                  <div className="benefit-item">
                    <FiCalendar className="benefit-icon" />
                    <p>View your private appointments and repair requests</p>
                  </div>
                  <div className="benefit-item">
                    <FiHeadphones className="benefit-icon" />
                    <p>Receive tailored assistance from our Client Service</p>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleCompleteInfoSubmit} className="signup-form">
              <h2 className="signup-title">Complete Your Profile</h2>

              <div className="signup-email-password-fields">
                <input
                  type="text"
                  className="signup-input half-width"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <input
                  type="text"
                  className="signup-input half-width"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="signup-email-password-fields">
                <select
                  className="signup-input half-width"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <input
                  type="number"
                  className="signup-input half-width"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>

              <div className="signup-buttons">
                <button
                  type="button"
                  onClick={skipCompleteInfo}
                  className="signup-button"
                >
                  Skip
                </button>
                <button type="submit" className="signup-button" disabled={loading}>
                  {loading ? 'Saving Info...' : 'Save Info'}
                </button>
              </div>
            </form>
          )}
        </div>

        <ToastContainer position="top-center" autoClose={5000} />
      </div>
    </div>
  );
};

export default SignUp;

import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [totalSales, setTotalSales] = useState(0);
  const [baseProductCount, setBaseProductCount] = useState(0);
  const [fullProductCount, setFullProductCount] = useState(0);
  const [jewelryProductCount, setJewelryProductCount] = useState(0);
  const [usersOverTime, setUsersOverTime] = useState({});
  const [userCount, setUserCount] = useState(0);
  const [salesByMethod, setSalesByMethod] = useState({});
  const [salesOverTime, setSalesOverTime] = useState({});

  useEffect(() => {
    fetchSalesData();
    countDocuments('baseProducts', setBaseProductCount);
    countDocuments('fullproducts', setFullProductCount);
    countDocuments('jewelry', setJewelryProductCount);
    countDocuments('users', setUserCount);
    fetchUserData();  // Call the function to fetch user data
  }, []);

  const fetchUserData = async () => {
    const userSnapshot = await getDocs(collection(firestore, "users"));
    const usersByDate = {};
  
    userSnapshot.docs.forEach(doc => {
      const userData = doc.data();
      if (userData.creationTime) {
        const creationDate = userData.creationTime.toDate().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        usersByDate[creationDate] = (usersByDate[creationDate] || 0) + 1;
      }
    });
  
    const dates = Object.keys(usersByDate).sort();
    const userCounts = dates.map(date => usersByDate[date]);
  
    setUsersOverTime({
      labels: dates,
      datasets: [{
        label: 'Users Registered Per Day',
        data: userCounts,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }],
    });
  };  

  const fetchSalesData = async () => {
    const salesSnapshot = await getDocs(collection(firestore, "sales"));
    let total = 0;
    const salesByDate = {};

    salesSnapshot.docs.forEach(doc => {
        const saleData = doc.data();
        const amount = parseFloat(saleData.amount);
        total += amount;

        const saleDate = new Date(saleData.timestamp).toISOString().split('T')[0];
        salesByDate[saleDate] = (salesByDate[saleDate] || 0) + amount;
    });

    setTotalSales(total.toFixed(2));

    const dates = Object.keys(salesByDate).sort();
    const salesAmounts = dates.map(date => salesByDate[date]);

    setSalesOverTime({
        labels: dates,
        datasets: [{
            label: 'Sales Per Day',
            data: salesAmounts,
            borderColor: 'black',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }],
    });
};

  const countDocuments = async (collectionName, setState) => {
    const snapshot = await getDocs(collection(firestore, collectionName));
    setState(snapshot.size);
  };

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="stats">
        <div className="stat-item">
          <div className="stat-header">Total Sales</div>
          <div className="stat-value">${totalSales}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Base Products</div>
          <div className="stat-value">{baseProductCount}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Full Products</div>
          <div className="stat-value">{fullProductCount}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Jewelry Products</div>
          <div className="stat-value">{jewelryProductCount}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Users</div>
          <div className="stat-value">{userCount}</div>
        </div>
        {salesOverTime.labels && (
          <div className="graph-item">
            <h2>Sales Per Day</h2>
            <div className="chart-container">
              <Line data={salesOverTime} options={{ responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
            </div>
          </div>
        )}
        {usersOverTime.labels && (
          <div className="graph-item">
            <h2>Users Registered Per Day</h2>
            <div className="chart-container">
              <Line data={usersOverTime} options={{ responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

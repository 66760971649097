import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EmailMarketing.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const EmailMarketing = () => {
  const [users, setUsers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [templateId, setTemplateId] = useState('');
  const [emailLogs, setEmailLogs] = useState([]);
  const [graphFilter, setGraphFilter] = useState('daily');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'users'));
        const usersData = querySnapshot.docs.map(doc => doc.data());
        setUsers(usersData);
      } catch (error) {
        toast.error('Failed to fetch user data');
      }
    };

    const fetchEmailLogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'emailmarketing'));
        const logsData = querySnapshot.docs.map(doc => doc.data());
        setEmailLogs(logsData);
      } catch (error) {
        toast.error('Failed to fetch email logs');
      }
    };

    fetchUsers();
    fetchEmailLogs();
  }, []);

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((e) => e !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  const handleSendEmails = async () => {
    if (templateId === '') {
      toast.error('Please enter a template ID');
      return;
    }

    try {
      const dynamicData = {
        // Add any dynamic data you want to pass to the template here
      };

      const response = await fetch('/send-bulk-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emails: selectedEmails,
          templateId,
          dynamicData,
        }),
      });

      if (response.ok) {
        toast.success('Emails sent successfully');
        const timestamp = Timestamp.now();

        // Log email data to Firestore
        await addDoc(collection(firestore, 'emailmarketing'), {
          emails: selectedEmails,
          templateId,
          timestamp,
        });

        setEmailLogs([...emailLogs, { emails: selectedEmails, templateId, timestamp }]);
      } else {
        toast.error('Failed to send emails');
      }
    } catch (error) {
      toast.error('Error sending emails');
    }
  };

  const getGraphData = () => {
    const filteredLogs = emailLogs.filter(log => {
      const logDate = log.timestamp.toDate();
      const now = new Date();

      if (graphFilter === 'daily') {
        return logDate.toDateString() === now.toDateString();
      } else if (graphFilter === 'monthly') {
        return logDate.getMonth() === now.getMonth() && logDate.getFullYear() === now.getFullYear();
      }
      return false;
    });

    const dates = filteredLogs.map(log => log.timestamp.toDate().toLocaleDateString());
    const emailCounts = dates.reduce((acc, date) => {
      acc[date] = (acc[date] || 0) + filteredLogs.filter(log => log.timestamp.toDate().toLocaleDateString() === date).reduce((count, log) => count + log.emails.length, 0);
      return acc;
    }, {});

    const labels = Object.keys(emailCounts);
    const data = Object.values(emailCounts);

    return {
      labels,
      datasets: [{
        label: `Emails Sent ${graphFilter === 'daily' ? 'Today' : 'This Month'}`,
        data,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }],
    };
  };

  return (
    <div className="email-marketing-container">
      <h2>Email Marketing</h2>
      <input
        type="text"
        placeholder="Enter SendGrid Template ID"
        value={templateId}
        onChange={(e) => setTemplateId(e.target.value)}
        className="template-id-input"
      />
      <div className="user-list">
        <table>
          <thead>
            <tr>
              <th>Select</th>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    id={`user-${index}`}
                    onChange={() => handleCheckboxChange(user.email)}
                  />
                </td>
                <td>{user.email}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={handleSendEmails} className="send-emails-button">Send Emails</button>
      <div className="graph-filter">
        <label>
          <input
            type="radio"
            value="daily"
            checked={graphFilter === 'daily'}
            onChange={(e) => setGraphFilter(e.target.value)}
          />
          Daily
        </label>
        <label>
          <input
            type="radio"
            value="monthly"
            checked={graphFilter === 'monthly'}
            onChange={(e) => setGraphFilter(e.target.value)}
          />
          Monthly
        </label>
      </div>
      <div className="graph-container">
        <Line data={getGraphData()} options={{ scales: { y: { beginAtZero: true } } }} />
      </div>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
};

export default EmailMarketing;

import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, query, where, orderBy } from 'firebase/firestore';
import { Modal, Button, message, DatePicker } from 'antd';
import { firestore } from './firebaseConfig';
import moment from 'moment';
import './SalesAnalysis.css';

const { RangePicker } = DatePicker;

const OrderAnalysis = () => {
  const [orderData, setOrderData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [usersData, setUsersData] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [showPendingOnly, setShowPendingOnly] = useState(false);
  const [showTodayOnly, setShowTodayOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUsersData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(usersData).length > 0) {
      fetchOrderData();
    }
  }, [usersData, dateRange, showPendingOnly, showTodayOnly]);

  const fetchUsersData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "users"));
    const users = {};
    querySnapshot.docs.forEach(doc => {
      users[doc.id] = doc.data().email;
    });
    setUsersData(users);
  };

  const fetchOrderData = async () => {
    let orderQuery = query(collection(firestore, "orders"), orderBy("timestamp", "desc"));
    if (dateRange.length > 0) {
      orderQuery = query(orderQuery, 
        where('timestamp', '>=', dateRange[0].startOf('day').toDate()), 
        where('timestamp', '<=', dateRange[1].endOf('day').toDate())
      );
    }
    if (showPendingOnly) {
      orderQuery = query(orderQuery, where('status', '==', 'Order Pending'));
    }
    if (showTodayOnly) {
      const startOfToday = moment().startOf('day').toISOString();
      const endOfToday = moment().endOf('day').toISOString();
      orderQuery = query(orderQuery, 
        where('timestamp', '>=', startOfToday), 
        where('timestamp', '<=', endOfToday)
      );
    }
    const querySnapshot = await getDocs(orderQuery);
    const orders = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const formattedTimestamp = new Date(data.timestamp).toLocaleString();

      return {
        id: doc.id,
        ...data,
        timestamp: formattedTimestamp,
        userId: usersData[data.userId] || data.userId,
      };
    });

    const total = orders.reduce((sum, record) => sum + parseFloat(record.amount), 0);
    setOrderData(orders);
    setTotalAmount(total.toFixed(2));
  };

  const showModal = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fulfillOrder = async () => {
    if (selectedOrder) {
      const orderRef = doc(firestore, "orders", selectedOrder.id);

      try {
        const salesQuerySnapshot = await getDocs(query(collection(firestore, "sales"), where("transactionId", "==", selectedOrder.transactionId)));
        if (!salesQuerySnapshot.empty) {
          const salesDoc = salesQuerySnapshot.docs[0];
          const salesProductRef = doc(firestore, "sales", salesDoc.id);

          await updateDoc(orderRef, { status: "Order Fulfilled" });
          await updateDoc(salesProductRef, { status: "Order Fulfilled" });

          message.success("Estado de la orden y el producto de la venta actualizado a Cumplido.");
          fetchOrderData();
          setIsModalVisible(false);
        } else {
          message.error("Producto de venta no encontrado.");
        }
      } catch (error) {
        console.error("Error al actualizar el estado de la orden y el producto de la venta:", error);
        message.error("Error al actualizar el estado de la orden y el producto de la venta.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="crm-container">
      <div className="header-container">
        <h2 className="title">Datos de Órdenes</h2>
        <div className="total-sales">
          Monto Total: ${totalAmount}
        </div>
      </div>
      <div className="filters-container">
        <Button onClick={() => setShowPendingOnly(!showPendingOnly)}>
          {showPendingOnly ? "Mostrar Todas las Órdenes" : "Mostrar Solo Pendientes"}
        </Button>
        <Button onClick={() => setShowTodayOnly(!showTodayOnly)}>
          {showTodayOnly ? "Mostrar Todas las Órdenes" : "Mostrar Solo Hoy"}
        </Button>
        <RangePicker onChange={(dates) => setDateRange(dates)} />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID Transacción</th>
              <th>Email del Usuario</th>
              <th>Monto</th>
              <th>Método de Pago</th>
              <th>Marca de Tiempo</th>
              <th>Estado</th>
              <th>Detalles</th>
            </tr>
          </thead>
          <tbody>
            {orderData.map((order) => (
              <tr key={order.id}>
                <td>{order.transactionId}</td>
                <td>{order.userId}</td>
                <td>${order.amount}</td>
                <td>{order.paymentMethod}</td>
                <td>{order.timestamp}</td>
                <td>{order.status}</td>
                <td><Button onClick={() => showModal(order)}>Ver</Button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedOrder && (
        <Modal
          title="Detalles de la Orden"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>Regresar</Button>,
            <Button key="fulfill" type="primary" onClick={fulfillOrder} disabled={selectedOrder.status === "Order Fulfilled"}>
              Cumplir
            </Button>
          ]}
        >
          <p><strong>ID de Transacción:</strong> {selectedOrder.transactionId}</p>
          <p><strong>Email del Usuario:</strong> {selectedOrder.userId}</p>
          <p><strong>Monto:</strong> ${selectedOrder.amount}</p>
          <p><strong>Método de Pago:</strong> {selectedOrder.paymentMethod}</p>
          <p><strong>Marca de Tiempo:</strong> {selectedOrder.timestamp}</p>
          <p><strong>Estado:</strong> {selectedOrder.status}</p>
          <p><strong>Artículos:</strong></p>
          {selectedOrder.items.map((item, index) => (
            <div key={index}>
              <p>Nombre: {item.name}</p>
              <p>Precio: ${item.price}</p>
              <p>Cantidad: {item.quantity}</p>
              <p>Descripción: {item.description}</p>
              <img src={item.imageUrls[0]} alt={item.name} style={{ width: "100px" }} />
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default OrderAnalysis;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCaretDown, faCaretUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useUser } from './UserContext';
import './AdminPanel.css';

// Importing other components
import CRM from './CRM';
import ProductAdding from './ProductAdding';
import JewelryAdding from './JewelryAdding';
import BaseProduct from './BaseProduct';
import BaseProductManagement from './BaseProductManagement';
import JewelryProductManagement from './JewelryProductManagement';
import SalesComponent from './SalesComponent';
import EmailMarketing from './EmailMarketing';
import SalesAnalysis from './SalesAnalysis';
import Dashboard from './Dashboard';
import OrderAnalysis from './OrderAnalysis';
import UserAnalysis from './UsersAnalysis';
import UsersCRM from './UsersCRM';
import IdealCustomer from './IdealCustomer';
import CatalogItem from './CatalogItem';
import Inventory from './Inventory';
import Sales from './Sales';
import ImageUploader from './ImageUploader'; // Import the ImageUploader component

const AdminPanel = () => {
  const { user } = useUser();
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [productManagementOpen, setProductManagementOpen] = useState(false);
  const [addProductOpen, setAddProductOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveComponent(user?.role === 'admin' ? 'dashboard' : 'salesComponent');
  }, [user?.role]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleProductManagement = () => {
    setProductManagementOpen(!productManagementOpen);
  };

  const toggleAddProduct = () => {
    setAddProductOpen(!addProductOpen);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('Logged out successfully');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'dashboard': return <Dashboard />;
      case 'baseProductManagement': return <BaseProductManagement />;
      case 'jewelryProductManagement': return <JewelryProductManagement />;
      case 'productAdding': return <ProductAdding />;
      case 'baseProduct': return <BaseProduct />;
      case 'jewelryAdding': return <JewelryAdding />;
      case 'catalogo': return <CatalogItem />;
      case 'orderManagement': return <OrderAnalysis />;
      case 'CRM': return <CRM />;
      case 'salesAnalysis': return <SalesAnalysis />;
      case 'salesComponent': return <SalesComponent />;
      case 'emailMarketing': return <EmailMarketing />;
      case 'UserAnalysis': return <UserAnalysis />;
      case 'UsersCRM': return <UsersCRM />;
      case 'IdealCustomer': return <IdealCustomer />;
      case 'inventory': return <Inventory />;
      case 'sales': return <Sales />;
      case 'imageUploader': return <ImageUploader />; // Add this case for ImageUploader
      default: return <Dashboard />;
    }
  };

  return (
    <div className="admin-panel">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <FontAwesomeIcon icon={sidebarVisible ? faChevronLeft : faChevronRight} onClick={toggleSidebar} className="toggle-sidebar-icon" />
      <div className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <div className="nav-container">
          <div className="nav-link" onClick={() => setActiveComponent('dashboard')}>
            Dashboard
          </div>

          {/* Product Management Dropdown */}
          <div className="nav-link-dropdown" onClick={toggleProductManagement}>
            Product Management
            <FontAwesomeIcon icon={productManagementOpen ? faCaretUp : faCaretDown} className="dropdown-icon" />
          </div>
          {productManagementOpen && (
            <div className="dropdown-container">
              <div className="dropdown-link" onClick={() => setActiveComponent('baseProductManagement')}>
                Clothing Management
              </div>
              <div className="dropdown-link" onClick={() => setActiveComponent('jewelryProductManagement')}>
                Jewelry Management
              </div>
            </div>
          )}

          {/* Add Product Dropdown */}
          <div className="nav-link-dropdown" onClick={toggleAddProduct}>
            Add Product
            <FontAwesomeIcon icon={addProductOpen ? faCaretUp : faCaretDown} className="dropdown-icon" />
          </div>
          {addProductOpen && (
            <div className="dropdown-container">
              <div className="dropdown-link" onClick={() => setActiveComponent('productAdding')}>
                Add New Product
              </div>
              <div className="dropdown-link" onClick={() => setActiveComponent('baseProduct')}>
                Add Base Product
              </div>
              <div className="dropdown-link" onClick={() => setActiveComponent('jewelryAdding')}>
                Add Jewelry Product
              </div>
            </div>
          )}

          <div className="nav-link" onClick={() => setActiveComponent('imageUploader')}>
            Image Uploader {/* This will be the link for the ImageUploader */}
          </div>

          <div className="nav-link" onClick={() => setActiveComponent('CRM')}>
            CRM
          </div>
          <div className="nav-link" onClick={() => setActiveComponent('emailMarketing')}>
            Email Marketing
          </div>
          <div className="nav-link" onClick={() => setActiveComponent('UserAnalysis')}>
            Users
          </div>
          <div className="nav-link" onClick={() => setActiveComponent('salesComponent')}>
            Sales
          </div>
        </div>
      </div>
      <div className="admin-content">
        {renderActiveComponent()}
      </div>
      <div className="user-badge">
        <div className="user-info">
          <div>{user?.email}</div>
          <FontAwesomeIcon icon={faSignOutAlt} onClick={handleLogout} className="logout-icon" />
        </div>
        <div className="user-circle">
          {user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

import React, { useState, useEffect } from 'react';
import { Table, Modal, InputNumber, Button, Form, Input, Select } from 'antd';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import './Inventory.css';  // Ensure this file contains appropriate styling

const { Option } = Select;
const { Search } = Input;

const Inventory = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [filterType, setFilterType] = useState('all');

    useEffect(() => {
        const fetchProducts = async () => {
            const baseQuerySnapshot = await getDocs(collection(firestore, "baseProducts"));
            const jewelryQuerySnapshot = await getDocs(collection(firestore, "jewelryProducts"));
            const fullQuerySnapshot = await getDocs(collection(firestore, "fullProducts"));

            const baseProducts = baseQuerySnapshot.docs.map(doc => ({
                type: 'base',
                id: doc.id,
                ...doc.data(),
            }));

            const jewelryProducts = jewelryQuerySnapshot.docs.map(doc => ({
                type: 'jewelry',
                id: doc.id,
                ...doc.data(),
            }));

            const fullProducts = fullQuerySnapshot.docs.map(doc => ({
                type: 'full',
                id: doc.id,
                ...doc.data(),
            }));

            setProducts([...baseProducts, ...jewelryProducts, ...fullProducts]);
            setLoading(false);
        };

        fetchProducts();
    }, []);

    const showEditModal = (product) => {
        setCurrentProduct(product);
        setQuantity(product.quantity);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        const collectionName = currentProduct.type === 'base' ? 'baseProducts' : currentProduct.type === 'jewelry' ? 'jewelryProducts' : 'fullProducts';
        const productRef = doc(firestore, collectionName, currentProduct.id);
        await updateDoc(productRef, { quantity });
        setProducts(products.map(product =>
            product.id === currentProduct.id ? { ...product, quantity } : product
        ));
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imageUrls',
            key: 'imageUrls',
            render: (imageUrls) => imageUrls && imageUrls.length > 0 ? <img src={imageUrls[0]} alt="product" style={{ width: 50, height: 50 }} /> : 'No Image'
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => `$${text}`,
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (text) => `$${text}`,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => (
                <span onClick={() => showEditModal(record)} style={{ cursor: 'pointer', color: 'blue' }}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Margin',
            dataIndex: 'margin',
            key: 'margin',
            render: (text) => `${text}%`,
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (text, record) => `$${(record.price * record.quantity).toFixed(2)}`,
        },
    ];

    const filteredProducts = products.filter(product => 
        (filterType === 'all' || product.type === filterType) &&
        product.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="inventory-container">
            <h1>Inventory Management</h1>
            <div className="inventory-filters">
                <Search
                    placeholder="Search by product name"
                    enterButton
                    onSearch={(value) => setSearchText(value)}
                    style={{ marginBottom: 20, width: 300 }}
                />
                <Select
                    defaultValue="all"
                    onChange={(value) => setFilterType(value)}
                    style={{ marginBottom: 20, width: 200 }}
                >
                    <Option value="all">All Products</Option>
                    <Option value="base">Base Products</Option>
                    <Option value="jewelry">Jewelry Products</Option>
                    <Option value="full">Full Products</Option>
                </Select>
            </div>
            <Table 
                columns={columns} 
                dataSource={filteredProducts.map(product => ({ ...product, key: product.id }))} 
                loading={loading}
                pagination={{ pageSize: 10 }}
                className="inventory-table"
            />
            <Modal 
                title="Edit Quantity" 
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Quantity">
                        <InputNumber min={0} value={quantity} onChange={setQuantity} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Inventory;

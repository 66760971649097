import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateDoc, query, where, getDocs, collection, doc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import { FaStripe, FaPaypal } from 'react-icons/fa'; // Stripe & PayPal icons
import { AiOutlineBank } from 'react-icons/ai'; // Zelle icon
import './CheckoutPayment.css';

const CheckoutPayment = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { orderNumber } = state || {}; // Fetch orderNumber from state
  const { user } = useUser();

  const [paymentMethod, setPaymentMethod] = useState('Stripe');
  const [orderData, setOrderData] = useState(null); // To store the fetched order data

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        if (user && orderNumber) {
          // Query Firestore for the document where orderNumber matches the field
          const ordersRef = collection(firestore, `users/${user.uid}/orders`);
          const q = query(ordersRef, where('orderNumber', '==', orderNumber));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Assuming order numbers are unique
            setOrderData({ id: doc.id, ...doc.data() }); // Set the order data with the Firestore document ID
          } else {
            console.error("Order not found");
          }
        }
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    fetchOrderData();
  }, [user, orderNumber]);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentConfirmation = async () => {
    try {
      // Simulate payment confirmation by updating the order with payment details
      if (user && orderData?.id) {
        const orderDoc = doc(firestore, `users/${user.uid}/orders/${orderData.id}`);
        await updateDoc(orderDoc, {
          paymentMethod,
          paid: true,
          status: "Payment completed"
        });

        // Simulate payment success and navigate to confirmation page
        navigate('/checkout/confirmation', { state: { orderNumber, shippingInfo: orderData, paymentMethod } });
      }
    } catch (error) {
      console.error("Error updating payment information: ", error);
    }
  };

  if (!orderData) {
    return <div>Loading...</div>; // Show a loading message while fetching order data
  }

  return (
    <div className="checkout-payment-container">
      {/* Contact and Shipping Info */}
      <div className="checkout-summary-container">
        <div className="checkout-summary-item">
          <div>Contact (Email)</div>
          <div>{orderData?.email}</div>
        </div>
        <div className="checkout-summary-item">
          <div>Phone</div>
          <div>{`${orderData?.phoneNumber.slice(0, 3)}-${orderData?.phoneNumber.slice(3, 6)}-${orderData?.phoneNumber.slice(6)}`}</div>
        </div>
        <div className="checkout-summary-item">
          <div>Ship to</div>
          <div>{`${orderData?.firstName} ${orderData?.lastName}, ${orderData?.address}, ${orderData?.address2 ? `${orderData?.address2},` : ''} ${orderData?.city}, ${orderData?.state}, ${orderData?.zipCode}, ${orderData?.country}`}</div>
        </div>
        <div className="checkout-summary-item">
          <div>Order Number</div>
          <div>{orderNumber}</div>
        </div>
        <div className="checkout-summary-item">
          <div>Shipping method</div>
          <div>Standard Shipping (3-4 Business Days) • Free</div>
        </div>
      </div>

      {/* Payment Section */}
      <div className="checkout-payment-content">
        <div className="checkout-payment-header">Payment</div>
        <div className="checkout-payment-description">All transactions are secure and encrypted.</div>

        <div className="checkout-payment-options">
          <div className="checkout-payment-option">
            <input
              type="radio"
              id="stripe"
              name="payment"
              value="Stripe"
              onChange={handlePaymentMethodChange}
              checked={paymentMethod === 'Stripe'}
              className="checkout-payment-radio"
            />
            <label htmlFor="stripe" className="checkout-payment-label">
              <FaStripe className="stripe-icon" />
              Stripe
            </label>
          </div>
          <div className="checkout-payment-option">
            <input
              type="radio"
              id="paypal"
              name="payment"
              value="PayPal"
              onChange={handlePaymentMethodChange}
              checked={paymentMethod === 'PayPal'}
              className="checkout-payment-radio"
            />
            <label htmlFor="paypal" className="checkout-payment-label">
              <FaPaypal className="payment-icon" />
              PayPal
            </label>
          </div>
          <div className="checkout-payment-option">
            <input
              type="radio"
              id="zelle"
              name="payment"
              value="Zelle"
              onChange={handlePaymentMethodChange}
              checked={paymentMethod === 'Zelle'}
              className="checkout-payment-radio"
            />
            <label htmlFor="zelle" className="checkout-payment-label">
              <AiOutlineBank className="payment-icon" />
              Zelle
            </label>
          </div>
        </div>

        <button className="checkout-payment-confirm-button" onClick={handlePaymentConfirmation}>
          Pay now
        </button>
      </div>
    </div>
  );
};

export default CheckoutPayment;

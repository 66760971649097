import React, { useEffect, useState, useRef, useContext } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { LanguageContext } from '../LanguageContext'; // Import the Language Context
import { useNavigate } from 'react-router-dom';
import './Style.css';

const Style = () => {
  const { language } = useContext(LanguageContext);
  const [styleImages, setStyleImages] = useState([]);

  const translations = {
    en: {
      clothing: 'CLOTHING ONLY',
      cadenas: 'CADENAS ONLY',
      combinations: 'COMBINATIONS ONLY',
    },
    es: {
      clothing: 'SOLO ROPA',
      cadenas: 'SOLO CADENAS',
      combinations: 'SOLO COMBINACIONES',
    },
  };

  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState([]);
  const itemsRef = useRef([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageDocs = await getDocs(collection(firestore, 'styleImages'));
        const images = imageDocs.docs.flatMap((doc) => doc.data().urls);
        setStyleImages(images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible((prev) => [...prev, entry.target]);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    itemsRef.current.forEach((item) => {
      if (item) observer.observe(item);
    });

    return () => {
      itemsRef.current.forEach((item) => {
        if (item) observer.unobserve(item);
      });
    };
  }, []);

  return (
    <div className="style-container">
      <div className="style-left">
        {styleImages[0] && (
          <div
            className={`style-image-large ${isVisible.includes(itemsRef.current[0]) ? 'fade-in-visible' : 'fade-in-hidden'}`}
            ref={(el) => (itemsRef.current[0] = el)}
            onClick={() => navigate('/shop')}
          >
            <img src={styleImages[0]} alt={translations[language].clothing} className="style-img" loading="lazy" />
            <h3 className="style-image-title">{translations[language].clothing}</h3>
          </div>
        )}
      </div>
      <div className="style-images">
        {styleImages[1] && (
          <div
            className={`style-image-item ${isVisible.includes(itemsRef.current[1]) ? 'fade-in-visible' : 'fade-in-hidden'}`}
            ref={(el) => (itemsRef.current[1] = el)}
            onClick={() => navigate('/shop')}
          >
            <img src={styleImages[1]} alt={translations[language].cadenas} className="style-img" loading="lazy" />
            <h3 className="style-image-title">{translations[language].cadenas}</h3>
          </div>
        )}
        {styleImages[2] && (
          <div
            className={`style-image-item-2 ${isVisible.includes(itemsRef.current[2]) ? 'fade-in-visible' : 'fade-in-hidden'}`}
            ref={(el) => (itemsRef.current[2] = el)}
            onClick={() => navigate('/shop')}
          >
            <img src={styleImages[2]} alt={translations[language].combinations} className="style-img" loading="lazy" />
            <h3 className="style-image-title">{translations[language].combinations}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Style;

import React, { useState, useEffect, useContext } from 'react';
import './CustomCookieConsent.css';
import ReactGA from 'react-ga';
import { useUser } from './UserContext'; // Correctly use the custom hook
import { doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import { app } from './firebaseConfig';
import Cookies from 'js-cookie';
import { LanguageContext } from './LanguageContext'; // Import Language Context

const CustomCookieConsent = () => {
  const { user } = useUser(); // Use the custom hook correctly
  const [isVisible, setIsVisible] = useState(false);
  const { setCookieConsent } = useContext(LanguageContext); // Access setCookieConsent from LanguageContext

  useEffect(() => {
    const checkConsent = async () => {
      if (user) {
        // If user is logged in, check for consent in Firestore
        const docRef = doc(getFirestore(app), 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.AnalyticsConsent) {
            initializeGA();
            setCookieConsent(true); // Set cookie consent to true in LanguageContext
          } else {
            setIsVisible(true);
          }
        } else {
          setIsVisible(true);
        }
      } else {
        // If user is not logged in, check for consent in browser cookies
        const consentGiven = Cookies.get('AnalyticsConsent');
        if (!consentGiven) {
          setIsVisible(true); // Show consent banner if no cookie is set
        } else if (consentGiven === 'true') {
          initializeGA();
          setCookieConsent(true); // Set cookie consent to true in LanguageContext
        }
      }
    };
    checkConsent();
  }, [user]);

  const initializeGA = () => {
    ReactGA.initialize('YOUR_GOOGLE_ANALYTICS_TRACKING_ID');
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  const acceptHandler = async () => {
    // Store consent locally in cookies if the user is not logged in
    Cookies.set('AnalyticsConsent', 'true');
    
    if (user) {
      // If user is logged in, also store consent in Firestore
      const docRef = doc(getFirestore(app), 'users', user.uid);
      await setDoc(docRef, { AnalyticsConsent: true }, { merge: true });
    }

    initializeGA();
    setCookieConsent(true); // Enable cookies for language in LanguageContext
    setIsVisible(false);
  };

  const declineHandler = async () => {
    Cookies.set('AnalyticsConsent', 'false');

    if (user) {
      // If user is logged in, store decline in Firestore
      const docRef = doc(getFirestore(app), 'users', user.uid);
      await setDoc(docRef, { AnalyticsConsent: false }, { merge: true });
    }

    Cookies.remove('language'); // Clear the language cookie if consent is denied
    setCookieConsent(false); // Disable cookie consent in LanguageContext
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="modal-backdrop">
      <div className="cookie-consent-modal">
        <h2>Cookie Consent</h2>
        <p>
          We use Google Analytics to improve your experience and for marketing purposes. By continuing to use our site, you agree to our use of cookies. For more details, please review our <a href="/privacy-policy">Privacy Policy</a> and <a href="/termsandconditions">Terms and Conditions</a>.
        </p>
        <div className="modal-actions">
          <button onClick={declineHandler} className="decline-button">Decline</button>
          <button onClick={acceptHandler} className="accept-button">Accept</button>
        </div>
      </div>
    </div>
  );
};

export default CustomCookieConsent;

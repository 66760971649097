import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import Navbar from '../Navbar';
import Testimonials from './Testimonials';
import AboutUs from './AboutUs';
import Style from './Style';
import { getDocs, collection } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const Home = ({ isLoading }) => {
  const [showContent, setShowContent] = useState(false);
  const sectionsRef = useRef([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [homeImage, setHomeImage] = useState('');
  const [homeImages, setHomeImages] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Track image load
  const [slideIn, setSlideIn] = useState(false); // Track when to trigger slide-in

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const imageDocs = await getDocs(collection(firestore, 'homepageImages'));
      const images = imageDocs.docs.flatMap((doc) => doc.data().urls);
      if (images.length > 0) {
        setHomeImages(images);
        setHomeImage(images[0]); // Set the first image as the hero image
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setShowContent(true);
    }
  }, [isLoading]);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true); // Set to true when the image is fully loaded
    setSlideIn(true); // Trigger slide-in once the image is loaded
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentSections = sectionsRef.current;
    currentSections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      currentSections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className="home-container">
      <Helmet>
        <title>LE SMOKE U® Official Site | Where Street Meets Elegance</title>
        <meta
          name="description"
          content="Discover the official site of LE SMOKE U® where street meets elegance. Explore our exclusive collections and join our circle for the latest in high fashion."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Preload Hero Image */}
        {homeImage && <link rel="preload" href={homeImage} as="image" />}
      </Helmet>

      <Navbar isSticky={!showContent} />

      <section className="hero-section" id="hero-section">
        {homeImage ? (
          <picture>
            <source srcSet={homeImage} type="image/webp" />
            <img
              src={homeImage}
              alt="Hero"
              className={`hero-image ${isImageLoaded ? 'fade-in' : ''}`} // Add the fade-in class after image loads
              onLoad={handleImageLoad} // Image loaded event
              loading="lazy"
            />
          </picture>
        ) : (
          <img src="/default-image.jpg" alt="Hero" className="hero-image" loading="lazy" />
        )}
        <div className={`hero-text ${slideIn ? 'slide-in' : ''}`}> {/* Apply slide-in class to text */}
          <h1
            className={`hero-h1 brand-title ${isScrolled ? 'shrink' : ''}`} // Add slide-in class after image load
          >
            LE SMOKE U
          </h1>
        </div>
      </section>

      <div ref={(el) => (sectionsRef.current[0] = el)} className="fade-in-section">
        <AboutUs />
      </div>

      <div ref={(el) => (sectionsRef.current[1] = el)} className="fade-in-section">
        <Testimonials />
      </div>

      <div ref={(el) => (sectionsRef.current[2] = el)} className="fade-in-section">
        <Style />
      </div>
    </div>
  );
};

export default Home;

import React, { useContext, useState } from 'react';
import { LanguageContext } from './LanguageContext'; // Import the LanguageContext
import './LanguageSwitcher.css'; // Import the LanguageSwitcher styles

const LanguageSwitcher = () => {
  const { changeLanguage, language } = useContext(LanguageContext); // Access the global language and change function
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (lang, option) => {
    changeLanguage(lang); // Change the global language
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="language-switcher">
      <label htmlFor="language-select">Country/region: </label>
      <div className="custom-dropdown">
        <div className="dropdown-header" onClick={toggleDropdown}>
          {language === 'en' ? 'United States (USD $)' : 'España (EUR €)'} 
          <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            <li onClick={() => handleOptionClick('en', 'United States (USD $)')}>
              United States (USD $)
            </li>
            <li onClick={() => handleOptionClick('es', 'España (EUR €)')}>
              España (EUR €)
            </li>
            {/* Add more options as needed */}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;

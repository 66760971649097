import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiShoppingBag, FiX, FiMenu } from 'react-icons/fi'; // Hamburger and cart icons
import { CartContext } from './Cart/CartContext';
import { LanguageContext } from './LanguageContext'; // Import LanguageContext
import './NavbarMobile.css';

const NavbarMobile = () => {
  const { cartCount } = useContext(CartContext); // Use CartContext to get the cart count
  const { language } = useContext(LanguageContext); // Use LanguageContext for language selection

  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  return (
    <>
      <nav className="navbar-mobile">
        <button className="navbar-mobile-hamburger-toggle" onClick={toggleNav}>
          <FiMenu className="navbar-mobile-hamburger-icon" />
        </button>
        <Link to="/" className="navbar-mobile-brand">
          LE SMOKE U
        </Link>
        <Link to="/cart" className="navbar-mobile-cart-link">
          <FiShoppingBag className="navbar-mobile-cart-icon" />
          {cartCount > 0 && <span className="navbar-mobile-cart-count">({cartCount})</span>}
        </Link>
      </nav>

      {/* Menu */}
      <div className={`menu-mobile-container ${navActive ? 'menu-mobile-active' : ''}`}>
        <button className="menu-mobile-close-icon" onClick={toggleNav}>
          <FiX />
        </button>
        <Link to="/" onClick={toggleNav} className="menu-mobile-link">
          Home
        </Link>
        <Link to="/shop" onClick={toggleNav} className="menu-mobile-link">
          Shop
        </Link>
        <Link to="/account" onClick={toggleNav} className="menu-mobile-link">
          Account
        </Link>
        <Link to="/terms" onClick={toggleNav} className="menu-mobile-link">
          Terms
        </Link>
      </div>
    </>
  );
};

export default NavbarMobile;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './TermsAndConditions.css';
import Navbar from './Navbar';
import Footer from './Footer/Footer';

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | LE SMOKE U</title>
        <meta name="description" content="Read the terms and conditions for using LE SMOKE U, including our policies on returns, exchanges, and privacy." />
      </Helmet>
      <Navbar />
      <div className="terms-container">
        <h2 className="terms-title">TÉRMINOS Y CONDICIONES</h2>

        <section className="terms-section">
          <h3 className="terms-subtitle">1. Introducción</h3>
          <p className="terms-text">
            Bienvenido a LE SMOKE U. Estos términos y condiciones describen las reglas y regulaciones para el uso de nuestro sitio web y servicios. Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes utilizando LE SMOKE U si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">2. Propiedad Intelectual</h3>
          <p className="terms-text">
            A menos que se indique lo contrario, LE SMOKE U y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en LE SMOKE U. Todos los derechos de propiedad intelectual están reservados. Puedes acceder a esto desde LE SMOKE U para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">3. Cuentas de Usuario</h3>
          <p className="terms-text">
            Cuando creas una cuenta con nosotros, debes proporcionarnos información que sea precisa, completa y actual en todo momento. El no hacerlo constituye una violación de los términos, lo que puede resultar en la terminación inmediata de tu cuenta en nuestro servicio.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">4. Devoluciones e Intercambios</h3>
          <p className="terms-text">
            No hacemos reembolsos, sin embargo, puedes intercambiar tus artículos durante un período de 30 días después de tu compra. Debes proporcionarnos el recibo de tu compra para que podamos validar la información. Todos los artículos deben tener todas las etiquetas y etiquetas adjuntas y deben estar en perfectas condiciones. LOS ARTÍCULOS EN VENTA FINAL y LAS TARJETAS DE REGALO no son intercambiables; son VENTA FINAL. El costo de envío de los artículos devueltos será pagado por el cliente.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">5. Actividades Prohibidas</h3>
          <p className="terms-text">
            Estás específicamente restringido de todas las siguientes acciones: publicar cualquier material del sitio web en cualquier otro medio; vender, sublicenciar y/o comercializar cualquier material del sitio web; realizar y/o mostrar públicamente cualquier material del sitio web; usar este sitio web de cualquier manera que sea o pueda ser dañina para este sitio web; usar este sitio web de cualquier manera que impacte el acceso del usuario a este sitio web; usar este sitio web contrario a las leyes y regulaciones aplicables, o de cualquier manera que pueda causar daño al sitio web, o a cualquier persona o entidad comercial.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">6. Limitación de Responsabilidad</h3>
          <p className="terms-text">
            En ningún caso LE SMOKE U, ni ninguno de sus funcionarios, directores y empleados, será responsable de nada que surja o esté relacionado de alguna manera con tu uso de este sitio web, ya sea bajo contrato. LE SMOKE U, incluidos sus funcionarios, directores y empleados, no serán responsables de ninguna responsabilidad indirecta, consecuente o especial que surja de o esté relacionada de alguna manera con tu uso de este sitio web.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">7. Indemnización</h3>
          <p className="terms-text">
            Por la presente indemnizas en la máxima medida a LE SMOKE U de y contra cualquier y/o todas las responsabilidades, costos, demandas, causas de acción, daños y gastos que surjan de alguna manera relacionados con tu incumplimiento de cualquiera de las disposiciones de estos Términos.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">8. Cambios en los Términos</h3>
          <p className="terms-text">
            LE SMOKE U está permitida para revisar estos Términos en cualquier momento que lo considere apropiado, y al usar este sitio web se espera que revises estos Términos de manera regular.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">9. Ley Aplicable y Jurisdicción</h3>
          <p className="terms-text">
            Estos Términos se regirán e interpretarán de acuerdo con las leyes de Caracas, Venezuela, y te sometes a la jurisdicción no exclusiva de los tribunales ubicados en Caracas para la resolución de cualquier disputa.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">10. Política de Privacidad</h3>
          <p className="terms-text">
            Estamos comprometidos a proteger tu privacidad. Esta Política de Privacidad establece cómo usamos tu información y qué medidas tomamos para protegerla. Al usar este sitio web, consientes el procesamiento descrito en esta política y garantizas que todos los datos proporcionados por ti son precisos.
          </p>
          <p className="terms-text">
            <strong>Recopilación de Datos:</strong> Podemos recopilar información personal como nombre, dirección de correo electrónico, dirección postal, número de teléfono, información de pago y datos de ubicación. También podemos recopilar información demográfica como edad, género y preferencias personales para mejorar nuestra estrategia de marketing.
          </p>
          <p className="terms-text">
            <strong>Uso de la Información:</strong> Usamos la información recopilada para mejorar nuestros productos y servicios, personalizar tu experiencia en nuestro sitio web, procesar transacciones y enviar correos electrónicos periódicos. También utilizamos tus datos de ubicación y CRM para mejorar nuestras campañas de marketing y ofrecerte contenido relevante.
          </p>
          <p className="terms-text">
            <strong>Protección de la Información:</strong> Implementamos diversas medidas de seguridad para mantener la seguridad de tu información personal. Sin embargo, no podemos garantizar la seguridad absoluta de los datos transmitidos a través de Internet.
          </p>
          <p className="terms-text">
            <strong>Divulgación a Terceros:</strong> No vendemos, intercambiamos ni transferimos a terceros tu información personal sin tu consentimiento, excepto para el propósito expreso de entregar el producto o servicio adquirido. Podemos compartir información demográfica genérica no vinculada a ninguna información personal identificable con nuestros socios comerciales, afiliados de confianza y anunciantes.
          </p>
          <p className="terms-text">
            <strong>Derechos del Usuario:</strong> Tienes derecho a acceder, rectificar, borrar tus datos personales y otros derechos establecidos en las leyes aplicables. Para ejercer estos derechos, por favor contáctanos a lesmokeu@gmail.com.
          </p>
          <p className="terms-text">
            <strong>Retención de Datos:</strong> Retenemos tus datos personales solo durante el tiempo necesario para cumplir con los propósitos para los que fueron recopilados, incluyendo para satisfacer cualquier requisito legal, contable o de informes.
          </p>
        </section>

        <section className="terms-section">
          <h3 className="terms-subtitle">11. Información de Contacto</h3>
          <p className="terms-text">
            Si tienes alguna pregunta sobre estos Términos, por favor contáctanos en lesmokeu@gmail.com.
          </p>
        </section>
      </div>
    </>
  );
}

export default TermsAndConditions;

import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default to 'en' (United States)
  const [cookieConsent, setCookieConsent] = useState(false); // Custom cookie consent

  useEffect(() => {
    // Check for analytics consent
    const consentGiven = Cookies.get('AnalyticsConsent');

    if (consentGiven === 'true') {
      setCookieConsent(true);
      // If language is already in cookies, set it
      const storedLanguage = Cookies.get('language');
      if (storedLanguage) {
        setLanguage(storedLanguage);
      } else {
        // If no stored language, detect browser language
        detectBrowserLanguage();
      }
    } else {
      detectBrowserLanguage(); // Use browser detection as fallback without storing in cookies
    }
  }, []);

  const detectBrowserLanguage = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith('es')) {
      setLanguage('es');
    } else {
      setLanguage('en');
    }
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    // Only store language in cookies if consent is given
    if (cookieConsent) {
      Cookies.set('language', lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, setCookieConsent }}>
      {children}
    </LanguageContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const Banner = () => {
  const [bannerImage, setBannerImage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBannerImage = async () => {
      const bannerImageDocs = await getDocs(collection(firestore, 'bannerImages'));
      const image = bannerImageDocs.docs.flatMap((doc) => doc.data().urls)[0];
      setBannerImage(image);
      setLoading(false);
    };
    fetchBannerImage();
  }, []);

  return (
    <section className="shop-banner">
      {loading ? (
        <div className="skeleton-loader" />
      ) : (
        <>
          <img src={bannerImage} alt="Shop Banner" className="banner-image" />
          <div className="shop-title">Shop</div>
        </>
      )}
    </section>
  );
};

export default Banner;

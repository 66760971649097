import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, Button, Table, Card } from 'antd';
import './Sales.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Sales = () => {
  const [salesData, setSalesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [usersData, setUsersData] = useState({});
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const [startDate, setStartDate] = useState(new Date('2024-05-01')); // Default start date to May 1
  const [endDate, setEndDate] = useState(new Date()); // Default end date to today's date
  const [paymentMethod, setPaymentMethod] = useState('All');
  const [chartType, setChartType] = useState('Line');
  const [isStartDateModalVisible, setIsStartDateModalVisible] = useState(false);
  const [isEndDateModalVisible, setIsEndDateModalVisible] = useState(false);
  const [summary, setSummary] = useState({
    totalSales: 0,
    totalOrders: 0,
    avgOrderValue: 0,
    unfulfilledPayments: 0,
    totalOrderAmount: 0,
  });
  const [topProducts, setTopProducts] = useState([]);
  const [salesByChannel, setSalesByChannel] = useState({});
  const [customerInsights, setCustomerInsights] = useState([]);
  const [isFilteredByMonth, setIsFilteredByMonth] = useState(false); // New state for month filter

  useEffect(() => {
    fetchSalesData();
    fetchOrdersData();
    fetchUsersData();
  }, []);

  useEffect(() => {
    filterData();
  }, [salesData, ordersData, startDate, endDate, paymentMethod]);

  const fetchSalesData = () => {
    onSnapshot(collection(firestore, 'sales'), (snapshot) => {
      const sales = snapshot.docs.map(doc => doc.data());
      setSalesData(sales);
    });
  };

  const fetchOrdersData = () => {
    onSnapshot(collection(firestore, 'orders'), (snapshot) => {
      const orders = snapshot.docs.map(doc => doc.data());
      console.log('Fetched Orders:', orders); // Debugging line
      setOrdersData(orders);
    });
  };

  const fetchUsersData = async () => {
    const usersSnapshot = await getDocs(collection(firestore, 'users'));
    const users = usersSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});
    setUsersData(users);
  };

  const filterData = async () => {
    const filteredSales = salesData.filter(sale => {
      const saleDate = new Date(sale.timestamp);
      const isWithinDateRange = saleDate >= startDate && saleDate <= endDate;
      const matchesPaymentMethod = paymentMethod === 'All' || sale.paymentMethod === paymentMethod;
      return isWithinDateRange && matchesPaymentMethod;
    });
  
    const filteredOrders = ordersData.filter(order => {
      const orderDate = new Date(order.timestamp);
      const isWithinDateRange = orderDate >= startDate && orderDate <= endDate;
      const matchesPaymentMethod = paymentMethod === 'All' || order.paymentMethod === paymentMethod;
      return isWithinDateRange && matchesPaymentMethod;
    });
  
    setFilteredSalesData(filteredSales);
    setFilteredOrdersData(filteredOrders);
  
    calculateSummary(filteredSales, filteredOrders); // Ensure this is called after setting filtered data
    calculateTopProducts(filteredSales);
    calculateSalesByChannel(filteredSales);
    await calculateCustomerInsights(filteredSales); // Call as async function
  };

  const calculateSummary = (sales, orders) => {
    console.log('Sales Data for Summary:', sales); // Debugging line
    console.log('Orders Data for Summary:', orders); // Debugging line
  
    const totalSales = sales.reduce((sum, sale) => sum + parseFloat(sale.amount), 0);
    const totalOrders = orders.length;
    const totalOrderAmount = orders.reduce((sum, order) => sum + parseFloat(order.amount), 0);
    const avgOrderValue = totalOrders ? (totalOrderAmount / totalOrders).toFixed(2) : 0;
  
    const unfulfilledPayments = totalOrderAmount - totalSales;
  
    setSummary({
      totalSales: totalSales.toFixed(2),
      totalOrders,
      avgOrderValue,
      unfulfilledPayments: unfulfilledPayments.toFixed(2), // Store unfulfilled payments amount
      totalOrderAmount: totalOrderAmount.toFixed(2),
    });
  
    console.log('Summary:', {
      totalSales: totalSales.toFixed(2),
      totalOrders,
      avgOrderValue,
      unfulfilledPayments: unfulfilledPayments.toFixed(2), // Log unfulfilled payments amount
      totalOrderAmount: totalOrderAmount.toFixed(2),
    }); // Debugging line
  };

  const calculateTopProducts = (sales) => {
    const productCounts = {};
    const productSales = {};
  
    sales.forEach(sale => {
      sale.items.forEach(item => {
        if (productCounts[item.name]) {
          productCounts[item.name] += item.quantity;
          productSales[item.name] += parseFloat(sale.amount);
        } else {
          productCounts[item.name] = item.quantity;
          productSales[item.name] = parseFloat(sale.amount);
        }
      });
    });
  
    const topProducts = Object.entries(productCounts)
      .map(([name, count]) => ({ name, count, sales: productSales[name] }))
      .sort((a, b) => b.sales - a.sales)
      .slice(0, 5);
  
    setTopProducts(topProducts);
  };

  const calculateSalesByChannel = (sales) => {
    const channelCounts = sales.reduce((acc, sale) => {
      const channel = sale.channel || 'Website';
      acc[channel] = (acc[channel] || 0) + parseFloat(sale.amount);
      return acc;
    }, {});
    setSalesByChannel(channelCounts);
  };

  const calculateCustomerInsights = async (sales) => {
    const customerCounts = {};
  
    for (const sale of sales) {
      const userId = sale.userId;
      if (userId in usersData) {
        const user = usersData[userId];
        const municipio = user.municipio || 'Unknown';
        if (customerCounts[municipio]) {
          customerCounts[municipio].amount += parseFloat(sale.amount);
          customerCounts[municipio].count += 1;
        } else {
          customerCounts[municipio] = { amount: parseFloat(sale.amount), count: 1 };
        }
      }
    }
  
    console.log('Customer Insights:', customerCounts); // Debugging line
    setCustomerInsights(Object.entries(customerCounts).map(([municipio, { amount, count }]) => ({ municipio, amount, count })));
  };

  const salesOverTime = () => {
    const salesByDate = {};
  
    filteredSalesData.forEach(sale => {
      const saleDate = new Date(sale.timestamp).toISOString().split('T')[0];
      salesByDate[saleDate] = (salesByDate[saleDate] || 0) + parseFloat(sale.amount);
    });
  
    const dates = Object.keys(salesByDate).sort();
    const salesAmounts = dates.map(date => salesByDate[date]);
  
    return {
      labels: dates,
      datasets: [{
        label: 'Sales Per Day',
        data: salesAmounts,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }],
    };
  };

  const salesByMonth = () => {
    const salesByMonth = {};
  
    filteredSalesData.forEach(sale => {
      const saleDate = new Date(sale.timestamp);
      const month = `${saleDate.getFullYear()}-${saleDate.getMonth() + 1}`;
      salesByMonth[month] = (salesByMonth[month] || 0) + parseFloat(sale.amount);
    });
  
    const months = Object.keys(salesByMonth).sort();
    const salesAmounts = months.map(month => salesByMonth[month]);
  
    return {
      labels: months,
      datasets: [{
        label: 'Sales Per Month',
        data: salesAmounts,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }],
    };
  };
  
  const paymentMethods = [...new Set(salesData.map(sale => sale.paymentMethod))];

  const chartData = isFilteredByMonth ? salesByMonth() : salesOverTime();

  return (
    <div className="sales-container">
      <h1>Sales Dashboard</h1>
      <div className="summary-cards">
        <Card title="Total Sales" bordered={false}>
          ${summary.totalSales}
        </Card>
        <Card title="Total Orders" bordered={false}>
          {summary.totalOrders}
        </Card>
        <Card title="Total Order Amount" bordered={false}>
          ${summary.totalOrderAmount}
        </Card>
        <Card title="Average Order Value" bordered={false}>
          ${summary.avgOrderValue}
        </Card>
        <Card title="Unfulfilled Payments" bordered={false}>
          ${summary.unfulfilledPayments} (Potential)
        </Card>
      </div>
      <div className="filters">
        <div>
          <label>Start Date: </label>
          <Button onClick={() => setIsStartDateModalVisible(true)}>Select Start Date</Button>
          <Modal
            title="Select Start Date"
            visible={isStartDateModalVisible}
            onCancel={() => setIsStartDateModalVisible(false)}
            footer={null}
            width={800}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setIsStartDateModalVisible(false);
              }}
              inline
            />
          </Modal>
        </div>
        <div>
          <label>End Date: </label>
          <Button onClick={() => setIsEndDateModalVisible(true)}>Select End Date</Button>
          <Modal
            title="Select End Date"
            visible={isEndDateModalVisible}
            onCancel={() => setIsEndDateModalVisible(false)}
            footer={null}
            width={800}
          >
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                setIsEndDateModalVisible(false);
              }}
              inline
            />
          </Modal>
        </div>
        <div>
          <label>Payment Method: </label>
          <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
            <option value="All">All</option>
            {paymentMethods.map(method => (
              <option key={method} value={method}>{method}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Chart Type: </label>
          <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
            <option value="Line">Line</option>
            <option value="Bar">Bar</option>
          </select>
        </div>
        <div>
          <Button onClick={() => setIsFilteredByMonth(!isFilteredByMonth)}>
            {isFilteredByMonth ? 'Show Daily Data' : 'Show Monthly Data'}
          </Button>
        </div>
      </div>
      <div className="graph-container">
        {chartType === 'Line' ? (
          <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
        ) : (
          <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
        )}
      </div>
      <div className="additional-insights">
        <Card title="Top Products" bordered={false}>
          <Table
            dataSource={topProducts}
            columns={[
              { title: 'Product', dataIndex: 'name', key: 'name' },
              { title: 'Quantity Sold', dataIndex: 'count', key: 'count' },
              { title: 'Sales Amount', dataIndex: 'sales', key: 'sales', render: (text) => `$${text}` },
            ]}
            pagination={false}
          />
        </Card>
        <Card title="Sales by Channel" bordered={false}>
          <Table
            dataSource={Object.entries(salesByChannel).map(([channel, amount]) => ({ channel, amount }))}
            columns={[
              { title: 'Channel', dataIndex: 'channel', key: 'channel' },
              { title: 'Sales Amount', dataIndex: 'amount', key: 'amount', render: (text) => `$${text}` },
            ]}
            pagination={false}
          />
        </Card>
        <Card title="Customer Insights" bordered={false}>
          <Table
            dataSource={customerInsights}
            columns={[
              { title: 'Municipio', dataIndex: 'municipio', key: 'municipio' },
              { title: 'Amount Spent', dataIndex: 'amount', key: 'amount', render: (text) => `$${text}` },
              { title: 'Order Count', dataIndex: 'count', key: 'count' },
            ]}
            pagination={false}
          />
        </Card>
      </div>
    </div>
  );  
};

export default Sales;

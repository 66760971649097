import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Use the custom hook

const ProtectedRoute = ({ children }) => {
  const { user } = useUser(); // Use custom hook for user data

  if (!user || !user.role || !['admin', 'vendedora'].includes(user.role)) {
    return <Navigate to="/auth" />;
  }

  return children;
};

export default ProtectedRoute;

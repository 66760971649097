import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Navbar from './Navbar';
import Home from './Home/Home';
import Shop from './Shop/Shop';
import AdminPanel from './AdminPanel';
import { UserProvider } from './UserContext';
import { CartProvider } from './Cart/CartContext';
import ProductDetails from './Shop/ProductDetails';
import JewelryDetails from './Shop/JewelryDetails';
import { AuthProvider } from './AuthContext';
import Cart from './Cart/Cart';
import Checkout from './Cart/Checkout';
import ProtectedRoute from './ProtectedRoute';
import TermsAndConditions from './TermsAndConditions';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import CustomCookieConsent from './CustomCookieConsent';
import SplashScreen from './SplashScreen';
import Testing from './Testing';
import PrivacyPolicy from './PrivacyPolicy';
import AffiliatePanel from './AffiliatePanel';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Footer from './Footer/Footer';
import { logPageView } from './analytics';
import Account from './Auth/Account';
import { LanguageProvider } from './LanguageContext';
import CombinationDetails from './Shop/CombinationDetails';
import Spinner from './Spinner';

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [fadeSplash, setFadeSplash] = useState(false);

  useEffect(() => {
    const slideTimer = setTimeout(() => {
      setFadeSplash(true);
    }, 3000);

    const hideTimer = setTimeout(() => {
      setShowSplash(false);
    }, 4500);

    return () => {
      clearTimeout(slideTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
      logPageView();
    }, [location]);
    return null;
  };

  if (showSplash) {
    return <SplashScreen isVisible={!fadeSplash} />;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProvider>
          <CartProvider>
            <LanguageProvider>
              <Router>
                <RouteChangeTracker />

                {/* Use a Layout component to conditionally render the Navbar and Footer */}
                <AppLayout>
                  <Routes>
                    <Route path="/" element={<Home isLoading={showSplash} />} />
                    <Route path="/testing" element={<Testing />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/termsandconditions" element={<TermsAndConditions />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/spinner" element={<Spinner />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout/*" element={<Checkout />} />
                    <Route path="/baseProducts/:productId" element={<ProductDetails />} />
                    <Route path="/jewelry/:productId" element={<JewelryDetails />} />
                    <Route
                      path="/admin-panel"
                      element={
                        <ProtectedRoute role="admin">
                          <AdminPanel />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/affiliate"
                      element={
                        <ProtectedRoute role="affiliate">
                          <AffiliatePanel />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/combination/:combinationId" element={<CombinationDetails />} />
                    <Route path="/account/*" element={<Account />} />
                  </Routes>
                </AppLayout>

                {/* Conditionally render Footer */}
                <FooterLayout />
              </Router>
            </LanguageProvider>
          </CartProvider>
        </UserProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

// Layout component for Navbar
const AppLayout = ({ children }) => {
  const location = useLocation();
  const isAdminPanel = location.pathname === '/admin-panel';

  return (
    <>
      {/* Conditionally render Navbar */}
      {!isAdminPanel && <Navbar />}
      {children}
    </>
  );
};

// Layout component for Footer
const FooterLayout = () => {
  const location = useLocation();
  const isAdminPanel = location.pathname === '/admin-panel';

  return (
    <>
      {/* Conditionally render Footer */}
      {!isAdminPanel && <Footer />}
    </>
  );
};

export default App;

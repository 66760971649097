import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react'; // Make sure to import this correctly
import { addDoc, collection } from 'firebase/firestore';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from './firebaseConfig';

const CatalogItem = () => {
    const [form] = Form.useForm();
    const [catalogItem, setCatalogItem] = useState({
        category: 'dijes', // Set category to "dijes"
        name: '',
        description: '',
        images: [],
        qrCode: ''
    });
    const [uploading, setUploading] = useState(false);

    const handleChange = (info) => {
        const updatedFiles = info.fileList.map(file => ({
            uid: file.uid,
            name: file.name,
            status: file.status,
            originFileObj: file.originFileObj,
            url: file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : null),
        }));
        setCatalogItem(prevState => ({
            ...prevState,
            images: updatedFiles
        }));
    };

    const uploadImages = async (files) => {
        return Promise.all(files.map(async file => {
            if (!file.originFileObj) {
                return file.url; // Use existing URL if file is already uploaded
            }
            const imageRef = storageRef(storage, `catalogo/${file.name}-${Date.now()}`);
            const snapshot = await uploadBytes(imageRef, file.originFileObj);
            return getDownloadURL(snapshot.ref);
        }));
    };

    const handleSubmit = async () => {
        setUploading(true);
        try {
            if (catalogItem.images.length === 0) {
                throw new Error("Please upload at least one image.");
            }
            const imageUrls = await uploadImages(catalogItem.images);
            const productData = {
                category: catalogItem.category,
                name: catalogItem.name,
                description: catalogItem.description,
                imageUrls
            };

            const docRef = await addDoc(collection(firestore, "catalogo"), productData);
            setCatalogItem(prevState => ({
                ...prevState,
                qrCode: docRef.id // Use the document ID as the QR code value
            }));
            message.success('Catalog item successfully added!');
            form.resetFields();
            setCatalogItem({ category: 'dijes', name: '', description: '', images: [], qrCode: '' });
        } catch (error) {
            console.error("Error adding catalog item: ", error);
            message.error(`Error adding catalog item: ${error.message}`);
        } finally {
            setUploading(false);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="form-container">
            <Form.Item label="Nombre del Producto" name="name" rules={[{ required: true }]}>
                <Input placeholder="Introduce el nombre del producto" onChange={e => setCatalogItem(prevState => ({
                    ...prevState,
                    name: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Descripción del Producto" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={2} placeholder="Introduce la descripción del producto" onChange={e => setCatalogItem(prevState => ({
                    ...prevState,
                    description: e.target.value
                }))} />
            </Form.Item>
            <Form.Item label="Imágenes del Producto" name="images">
                <Upload
                    listType="picture-card"
                    fileList={catalogItem.images}
                    onPreview={file => {
                        const src = file.url;
                        const imgWindow = window.open(src, '_blank');
                        imgWindow.document.write(`<img src="${src}" style="width:100%;">`);
                    }}
                    onChange={handleChange}
                    beforeUpload={() => false}
                    multiple
                >
                    {catalogItem.images.length >= 8 ? null : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>}
                </Upload>
            </Form.Item>
            {catalogItem.qrCode && (
                <Form.Item label="QR Code">
                    <QRCode value={catalogItem.qrCode} size={128} level="H" /> 
                </Form.Item>
            )}
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={uploading}>
                    Enviar Datos del Producto
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CatalogItem;
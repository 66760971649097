import React from 'react';
import './MarketingMaterials.css';
import Product1 from './Home/Product1.jpeg';
import Product2 from './Home/Product2.png';
import Product3 from './Home/Product3.jpeg';
import Home from './Home/Home.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const MarketingMaterials = () => {
  const materialsCount = 4;

  const handleDownloadAllAsZip = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder("marketing_materials");

    imgFolder.file("Product1.png", fetch(Product1).then(res => res.blob()));
    imgFolder.file("Product2.png", fetch(Product2).then(res => res.blob()));
    imgFolder.file("Product3.png", fetch(Product3).then(res => res.blob()));
    imgFolder.file("Home.png", fetch(Home).then(res => res.blob()));

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "marketing_materials.zip");
  };

  const handleDownloadIndividual = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, filename);
  };

  return (
    <div className="dashboard-container">
      <h1>Marketing Materials</h1>
      <p>Welcome to the marketing materials section. Here you can download all the promotional content you need for your campaigns. Please use only the provided materials to ensure brand consistency. Affiliates receive a commission based on the affiliate links on sales generated through these materials.</p>
      <div className="materials-container">
        <div className="material-card">
          <h3>All Materials</h3>
          <div className="material-item">
            <FontAwesomeIcon icon={faFolderOpen} size="6x" />
            <button className="download-zip-button" onClick={handleDownloadAllAsZip}>Download All as Zip</button>
          </div>
        </div>
        <div className="material-card">
          <h3>Total Materials</h3>
          <div className="material-item">
            <p>{materialsCount} items available for download</p>
          </div>
        </div>
        <div className="material-card">
          <h3>Product 1</h3>
          <div className="material-item">
            <img src={Product1} alt="Product 1" className="material-image" />
            <button className="download-button" onClick={() => handleDownloadIndividual(Product1, 'Product1.png')}>Download</button>
          </div>
        </div>
        <div className="material-card">
          <h3>Product 2</h3>
          <div className="material-item">
            <img src={Product2} alt="Product 2" className="material-image" />
            <button className="download-button" onClick={() => handleDownloadIndividual(Product2, 'Product2.png')}>Download</button>
          </div>
        </div>
        <div className="material-card">
          <h3>Product 3</h3>
          <div className="material-item">
            <img src={Product3} alt="Product 3" className="material-image" />
            <button className="download-button" onClick={() => handleDownloadIndividual(Product3, 'Product3.png')}>Download</button>
          </div>
        </div>
        <div className="material-card">
          <h3>Home</h3>
          <div className="material-item">
            <img src={Home} alt="Home" className="material-image" />
            <button className="download-button" onClick={() => handleDownloadIndividual(Home, 'Home.png')}>Download</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingMaterials;

import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import CheckoutShipping from './CheckoutShipping';
import CheckoutPayment from './CheckoutPayment';
import CheckoutConfirmation from './CheckoutConfirmation';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useUser } from '../UserContext';
import Cookies from 'js-cookie';
import CustomCookieConsent from '../CustomCookieConsent';
import { LanguageContext } from '../LanguageContext';
import './Checkout.css';
import { message } from 'antd';
import Skeleton from 'react-loading-skeleton';

const Checkout = () => {
  const location = useLocation();
  const { user } = useUser();
  const { cookieConsent } = useContext(LanguageContext);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subtotal, setSubtotal] = useState('0.00');
  const [taxRate, setTaxRate] = useState(0);
  const [taxes, setTaxes] = useState(0);

  useEffect(() => {
    if (!user) {
      message.info("Please log in to view your cart.");
      return;
    }

    const cartCollectionRef = collection(firestore, `users/${user.uid}/cart`);
    const unsubscribe = onSnapshot(cartCollectionRef, (snapshot) => {
      const items = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCartItems(items);
      calculateSubtotal(items);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const calculateSubtotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    setSubtotal(total);
  };

  useEffect(() => {
    const setLocationAndTaxRate = () => {
      const locationCookie = Cookies.get('user-location');
      let taxRate = 0;

      if (!locationCookie && cookieConsent) {
        const mockLocation = { country: 'US' };
        Cookies.set('user-location', JSON.stringify(mockLocation), { expires: 7 });
        taxRate = 0.07;
      } else if (locationCookie) {
        const locationData = JSON.parse(locationCookie);
        if (locationData.country === 'US') {
          taxRate = 0.07;
        } else if (locationData.country === 'CA') {
          taxRate = 0.13;
        } else {
          taxRate = 0;
        }
      }

      setTaxRate(taxRate);
      const calculatedTaxes = parseFloat(subtotal) * taxRate;
      setTaxes(calculatedTaxes.toFixed(2));
    };

    setLocationAndTaxRate();
  }, [subtotal, cookieConsent]);

  return (
    <div className="checkout-page-container">
      <CustomCookieConsent />
      
      <div className="checkout-page-content">
        <div className="checkout-page-left">
          {/* Step Navigation Bar */}
          <div className="checkout-page-steps">
            <ul className="checkout-page-steps-list">
              <li className={`checkout-page-step ${location.pathname === '/checkout/shipping' ? 'checkout-page-step-completed' : ''}`}>
                1. Shipping
              </li>
              <li className={`checkout-page-step ${location.pathname === '/checkout/payment' ? 'checkout-page-step-completed' : ''}`}>
                2. Payment Method
              </li>
              <li className={`checkout-page-step ${location.pathname === '/checkout/confirmation' ? 'checkout-page-step-completed' : ''}`}>
                3. Order Confirmation
              </li>
            </ul>
          </div>

          <Routes>
            <Route path="/" element={<Navigate to="/checkout/shipping" />} />
            <Route path="/shipping" element={<CheckoutShipping />} />
            <Route path="/payment" element={<CheckoutPayment />} />
            <Route path="/confirmation" element={<CheckoutConfirmation />} />
          </Routes>
        </div>

        <div className="checkout-page-right">
          <div className="checkout-page-order-summary">
            <h2 className="checkout-page-order-summary-title">Your order summary</h2>
            <div className="checkout-page-order-items">
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="checkout-page-order-item-skeleton">
                    <Skeleton width={100} height={110} className="item-image-skeleton" />
                    <div className="item-details-skeleton">
                      <Skeleton width={180} height={20} className="item-name-skeleton" />
                      <Skeleton width={80} height={20} className="item-price-skeleton" />
                    </div>
                  </div>
                ))
              ) : (
                cartItems.map((item, index) => (
                  <div key={index} className="checkout-page-order-item">
                    <img src={item.imageUrls[0]} alt={item.name} className="checkout-page-order-item-image" />
                    <div className="checkout-page-order-item-details">
                      <p className="checkout-page-order-item-name">{item.name}</p>
                      <p className="checkout-page-order-item-info">{item.color}, {item.size}</p>
                      <p className="checkout-page-order-item-qty">Qty: {item.quantity}</p>
                    </div>
                    <p className="checkout-page-order-item-price">${item.price.toFixed(2)}</p>
                  </div>
                ))
              )}
            </div>

            <div className="checkout-page-order-summary-total">
              <div className="checkout-page-subtotal">
                <span>Subtotal</span> <span>${subtotal}</span>
              </div>
              <div className="checkout-page-shipping">
                <span>Standard shipping</span> <span>Free</span>
              </div>
              <div className="checkout-page-taxes">
                <span>Taxes</span> <span>${taxes}</span>
              </div>
              <div className="checkout-page-total">
                <span>Total</span> <span>${(parseFloat(subtotal) + parseFloat(taxes)).toFixed(2)}</span>
              </div>
            </div>

            <div className="checkout-page-extra-info">
              <p>Need help? Contact us</p>
              <p>Free shipping and returns</p>
              <p>Secure payments</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { app } from './firebaseConfig';
import { Line } from 'react-chartjs-2';
import { useUser } from './UserContext';
import './Dashboard.css';

const AffiliateDashboard = () => {
  const { user } = useUser();  // Use the custom hook for user data
  const [sales, setSales] = useState([]);
  const [salesOverTime, setSalesOverTime] = useState({});
  const [totalSales, setTotalSales] = useState(0);
  const [links, setLinks] = useState([]);
  const [commission, setCommission] = useState(0);
  const [commissionHistory, setCommissionHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSales();
    fetchAffiliateData();
  }, [user?.uid]);

  const fetchSales = async () => {
    const salesQuery = query(
      collection(getFirestore(app), 'sales'),
      where('affiliateId', '==', user?.uid)
    );
    const salesSnapshot = await getDocs(salesQuery);
    const salesData = salesSnapshot.docs.map(doc => doc.data());

    let total = 0;
    const salesByDate = {};

    salesData.forEach(sale => {
      total += parseFloat(sale.amount);
      const saleDate = new Date(sale.timestamp).toISOString().split('T')[0];
      salesByDate[saleDate] = (salesByDate[saleDate] || 0) + parseFloat(sale.amount);
    });

    setSales(salesData);
    setTotalSales(total.toFixed(2));

    const dates = Object.keys(salesByDate).sort();
    const salesAmounts = dates.map(date => salesByDate[date]);

    setSalesOverTime({
      labels: dates,
      datasets: [{
        label: 'Sales Per Day',
        data: salesAmounts,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }],
    });
  };

  const fetchAffiliateData = async () => {
    const db = getFirestore(app);
    const docRef = doc(db, 'users', user?.uid, 'affiliate', 'data');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setLinks(data.links || []);
      setCommission(data.commission || 0);
      setCommissionHistory(data.commissionHistory || []);
    }
    setLoading(false);
  };

  const commissionData = {
    labels: commissionHistory.map(entry => entry.date),
    datasets: [
      {
        label: 'Commission Over Time',
        data: commissionHistory.map(entry => entry.amount),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <h1>Affiliate Dashboard</h1>
      <div style={{ border: '1px solid black', padding: '0', borderRadius: '5px', marginBottom: '10px' }}>
        <p>Welcome to our exclusive affiliate program...</p>
        <p>To remain in the program, you are required to generate a minimum of 10 sales per month...</p>
      </div>
      <div className="stats">
        <div className="stat-item">
          <div className="stat-header">Total Sales</div>
          <div className="stat-value">${totalSales}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Number of Sales</div>
          <div className="stat-value">{sales.length}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Total Links Generated</div>
          <div className="stat-value">{links.length}</div>
        </div>
        <div className="stat-item">
          <div className="stat-header">Total Commission</div>
          <div className="stat-value">${commission.toFixed(2)}</div>
        </div>
        {salesOverTime.labels && (
          <div className="graph-item">
            <h2>Sales Per Day</h2>
            <div className="chart-container">
              <Line data={salesOverTime} options={{ scales: { y: { beginAtZero: true } } }} />
            </div>
          </div>
        )}
        {commissionData.labels && (
          <div className="graph-item">
            <h2>Commission Over Time</h2>
            <div className="chart-container">
              <Line data={commissionData} options={{ scales: { y: { beginAtZero: true } } }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateDashboard;

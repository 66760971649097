import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Select, Button, message, Space } from 'antd';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import './SalesComponent.css';

const { Option } = Select;

const SalesComponent = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [receiptItems, setReceiptItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountApplied, setDiscountApplied] = useState(false);

  useEffect(() => {
    const fetchUsersAndProducts = async () => {
      try {
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const baseProductsSnapshot = await getDocs(collection(firestore, 'baseProducts'));
        const jewelryProductsSnapshot = await getDocs(collection(firestore, 'jewelryProducts'));
        const fullProductsSnapshot = await getDocs(collection(firestore, 'fullProducts'));

        const fetchedUsers = usersSnapshot.docs.map(doc => ({
          uid: doc.id,
          email: doc.data().email,
          points: doc.data().points || 0,
          creationTime: doc.data().creationTime.toDate().toLocaleString(), // Convert to date and format
          username: `${doc.data().firstName} ${doc.data().lastName}`,
          phoneNumber: doc.data().phoneNumber,
          municipio: doc.data().municipio,
          gender: doc.data().gender,
        }));

        const fetchedBaseProducts = baseProductsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
          description: doc.data().description || "Sin descripción disponible",
          imageUrl: (doc.data().imageUrls && doc.data().imageUrls[0]) || "https://example.com/default-image.png"
        }));

        const fetchedJewelryProducts = jewelryProductsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
          description: doc.data().description || "Sin descripción disponible",
          imageUrl: (doc.data().imageUrls && doc.data().imageUrls[0]) || "https://example.com/default-image.png"
        }));

        const fetchedFullProducts = fullProductsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
          description: doc.data().description || "Sin descripción disponible",
          imageUrl: (doc.data().imageUrls && doc.data().imageUrls[0]) || "https://example.com/default-image.png"
        }));

        const allProducts = [...fetchedBaseProducts, ...fetchedJewelryProducts, ...fetchedFullProducts];

        console.log("Usuarios obtenidos:", fetchedUsers);
        console.log("Productos obtenidos:", allProducts);

        setUsers(fetchedUsers);
        setProducts(allProducts);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        message.error("Error al obtener los datos: " + error.message);
      }
    };

    fetchUsersAndProducts();
  }, []);

  const handleUserChange = (value) => {
    const user = users.find(u => u.uid === value);
    setSelectedUser(user);
    setDiscountApplied(false);
  };

  const handleProductChange = (items) => {
    const receiptItems = items.map(({ product, quantity }) => {
      const productDetail = products.find(p => p.id === product);
      return {
        name: productDetail.name,
        price: productDetail.price,
        quantity,
        total: productDetail.price * quantity
      };
    });
    setReceiptItems(receiptItems);
    calculateTotal(receiptItems, discountApplied);
  };

  const calculateTotal = (items, applyDiscount) => {
    const total = items.reduce((acc, item) => acc + item.total, 0);
    const discountedTotal = applyDiscount ? total * 0.9 : total;
    setTotalAmount(discountedTotal.toFixed(2));
  };

  const handleRedeemPoints = () => {
    if (selectedUser && selectedUser.points >= 10) {
      setDiscountApplied(true);
      calculateTotal(receiptItems, true);
    } else {
      message.error("El usuario no tiene suficientes puntos para canjear.");
    }
  };

  const handleSubmit = async (values) => {
    const { userUid, products: productsForm, paymentMethod } = values;
    const transactionId = uuidv4(); // Generar un ID de transacción único

    const items = productsForm.map(({ product, quantity }) => {
      const productDetail = products.find(p => p.id === product);
      if (!productDetail) {
        message.error(`No se encontraron detalles del producto para el ID de producto: ${product}`);
        return null;
      }
      return {
        productId: productDetail.id,
        quantity: quantity,
        description: productDetail.description,
        imageUrls: [productDetail.imageUrl],
        name: productDetail.name,
        price: productDetail.price
      };
    }).filter(item => item !== null);

    if (items.length === 0) {
      message.error("No se encontraron detalles válidos del producto para registrar la venta.");
      return;
    }

    const totalAmount = items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const finalAmount = discountApplied ? totalAmount * 0.9 : totalAmount;
    const timestamp = new Date().toISOString();

    const saleData = {
      transactionId,
      userId: userUid,
      items,
      amount: finalAmount,
      paymentMethod,
      status: "Pending",
      timestamp: timestamp,
      channel: "instore", // Establecer el canal predeterminado a instore
      discountApplied: discountApplied ? "Yes" : "No"
    };

    console.log("Enviando datos de venta:", saleData);

    try {
      await addDoc(collection(firestore, 'sales'), saleData);
      
      let pointsToAdd = finalAmount > 250 ? 10 : 5;

      if (discountApplied) {
        pointsToAdd -= 10; // Subtract the redeemed points
      }

      const userRef = doc(firestore, 'users', selectedUser.uid);
      await updateDoc(userRef, {
        points: selectedUser.points + pointsToAdd
      });

      message.success('¡Venta registrada con éxito!');
      form.resetFields();
      setReceiptItems([]);
      setSelectedUser(null);
      setTotalAmount(0);
      setDiscountApplied(false);
    } catch (error) {
      console.error("Error al guardar la venta:", error);
      message.error("Error al registrar la venta.");
    }
  };

  return (
    <div className="sales-container">
      <h1>Registro de Venta</h1>
      <Form form={form} layout="vertical" onFinish={handleSubmit} autoComplete="off" className="sales-form">
        <Form.Item label="Correo del Usuario" name="userUid" rules={[{ required: true, message: 'Por favor seleccione el usuario!' }]}>
          <Select 
            showSearch
            placeholder="Seleccione un Usuario"
            optionFilterProp="children"
            onChange={handleUserChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {users.map(user => (
              <Option key={user.uid} value={user.uid}>{user.email}</Option>
            ))}
          </Select>
        </Form.Item>

        {selectedUser && (
          <div className="user-info">
            <div className="user-info-row">
              <p>Nombre: {selectedUser.username}</p>
              <p>Puntos de lealtad: {selectedUser.points}</p>
              <p>Fecha de Creación: {selectedUser.creationTime}</p>
            </div>
            <div className="user-info-row">
              <p>Teléfono: {selectedUser.phoneNumber}</p>
              <p>Municipio: {selectedUser.municipio}</p>
              <p>Género: {selectedUser.gender}</p>
            </div>
            {selectedUser.points >= 10 && !discountApplied && (
              <Button onClick={handleRedeemPoints}>Canjear 10 puntos por 10% de descuento</Button>
            )}
          </div>
        )}

        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="product-space">
                  <Form.Item
                    {...restField}
                    name={[name, 'product']}
                    fieldKey={[fieldKey, 'product']}
                    rules={[{ required: true, message: 'Seleccione el producto!' }]}
                  >
                    <Select 
                      placeholder="Seleccionar Producto" 
                      style={{ width: 200 }}
                      onChange={() => {
                        handleProductChange(form.getFieldValue('products'));
                      }}
                    >
                      {products.map(product => (
                        <Option key={product.id} value={product.id}>{product.name} - ${product.price.toFixed(2)}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'quantity']}
                    fieldKey={[fieldKey, 'quantity']}
                    initialValue={1} // Set default quantity to 1
                    rules={[{ required: true, message: 'Seleccione la cantidad!' }]}
                  >
                    <InputNumber 
                      min={1} 
                      placeholder="Cantidad" 
                      onChange={() => {
                        handleProductChange(form.getFieldValue('products'));
                      }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Button type="dashed" onClick={() => add({ quantity: 1 })} block icon={<PlusOutlined />}>
                Agregar Producto
              </Button>
            </>
          )}
        </Form.List>

        <Form.Item label="Método de Pago" name="paymentMethod" rules={[{ required: true, message: 'Por favor seleccione el método de pago!' }]}>
          <Select placeholder="Seleccionar Método de Pago">
            <Option value="Cash">Efectivo</Option>
            <Option value="Punto de Venta">Punto de Venta</Option>
            <Option value="Zelle">Zelle</Option>
            <Option value="Pago Movil">Pago Móvil</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Registrar Venta
          </Button>
        </Form.Item>

        {receiptItems.length > 0 && (
          <div className="receipt">
            <h3>Recibo</h3>
            <ul>
              {receiptItems.map((item, index) => (
                <li key={index}>
                  {item.quantity}x {item.name} - ${item.total.toFixed(2)}
                </li>
              ))}
            </ul>
            <p>Total: ${totalAmount}</p>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SalesComponent;

// UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, onSnapshot, getFirestore } from 'firebase/firestore';
import { app } from './firebaseConfig';

// Create the context
const UserContext = createContext();

// Custom hook to consume the UserContext
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // Add a small delay before accessing the Firestore document
        setTimeout(() => {
          const userDocRef = doc(db, 'users', authUser.uid);
          console.log('Accessing Firestore document for UID:', authUser.uid);
    
          const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
              const userData = { uid: authUser.uid, email: authUser.email, ...doc.data() };
              console.log('User data fetched from Firestore:', userData);
              setUser(userData);
            } else {
              console.warn('User document does not exist in Firestore. Using authUser data as fallback.');
              setUser(authUser);
            }
            setLoading(false);
          }, (snapshotError) => {
            console.error('Error fetching user document from Firestore:', snapshotError);
          });
    
          return unsubscribeUser;
        }, 1000); // 1-second delay
      } else {
        console.log('No authenticated user. Setting user state to null.');
        setUser(null);
        setLoading(false);
      }
    }, (authError) => {
      console.error('Error with onAuthStateChanged:', authError);
    });
  
    return () => unsubscribe();
  }, [auth, db]);  

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, loading, logout }}>
      {!loading ? children : null} {/* Only render children after loading */}
    </UserContext.Provider>
  );
};

import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext'; // Import the context
import './AboutUs.css';
import aboutImage from './Image2.png'; // Replace with your image path

const AboutUs = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: 'one of everything really good',
      text: 'At our company, our philosophy is to create one of everything really good. That means a curated collection of high-quality, high-performance essentials you rely on every day. The ones you love, trust, and always come back to.',
      button: 'LEARN MORE',
    },
    es: {
      title: 'uno de todo realmente bueno',
      text: 'En nuestra empresa, nuestra filosofía es crear uno de todo realmente bueno. Eso significa una colección curada de elementos esenciales de alto rendimiento y alta calidad en los que confías cada día.',
      button: 'SABER MÁS',
    },
  };

  return (
    <div className="about-us-section">
      <img src={aboutImage} alt="About Us" className="about-us-image" />
      <div className="about-us-overlay">
        <h1 className="about-us-title">{translations[language].title}</h1>
        <p className="about-us-text">{translations[language].text}</p>
        <button className="about-us-button">{translations[language].button}</button>
      </div>
    </div>
  );
};

export default AboutUs;
